import { Avatar, Dropdown, Menu, Input, Pagination, Select, Tag } from "antd";
import { useState, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import DisciplineCards from "./components/DisciplineCards";
import AttendanceTable from "../attendance/components/AttendanceTable";
import EmployeTable from "../employees/components/EmployeTable";
import { EmployeeData } from "../../../constants/EmployeesData";
import Disciplineform from "./components/Disciplineform";
import { DownOutlined } from "@ant-design/icons";
import Button from "../components/Button";
import D1 from "../../../imgs/D1.jpg";
import D2 from "../../../imgs/D2.jpg";
import D3 from "../../../imgs/D3.jpg";
import D4 from "../../../imgs/D4.jpg";
import D5 from "../../../imgs/D5.jpg";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;

const handleOpenPDF = () => {
  // Open the PDF in a new tab (no need to include "/public" in the path)
  window.open("/form.pdf", "_blank");
};

const columnHeaders = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Employee Name",
    dataIndex: "employeeName",
    key: "employeeName",
    render: (name, row) => (
      <div className="flex items-center">
        <Avatar
          src={
            "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg"
          }
          className="border-2 border-primary mr-2 w-10 h-10"
        />
        <p className="text-primary capitalize">{name}</p>
      </div>
    ),
  },
  {
    title: "Form Type",
    dataIndex: "formType",
    key: "formType",
  },
  {
    title: "Incident Date",
    dataIndex: "incidentDate",
    key: "incidentDate",
  },
  {
    title: "Reported By",
    dataIndex: "reportedBy",
    key: "reportedBy",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Action Taken",
    dataIndex: "actionTaken",
    key: "actionTaken",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      const statusColors = {
        Resolved: "green",
        Pending: "yellow",
        Escalated: "red",
      };

      return (
        <Tag
          color={statusColors[status] || "gray"}
          className="text-xs font-normal px-2 py-1"
        >
          {status}
        </Tag>
      );
    },
  },
  {
    title: "File",
    dataIndex: "file",
    key: "file",
    render: (name, row) => (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          handleOpenPDF();
        }}
      >
        <img
          src="https://cdn-icons-png.flaticon.com/512/80/80942.png"
          className=" w-30 h-8"
        />
        {/* <p className="text-primary capitalize">{name}</p> */}
      </div>
    ),
  },
];

const columns = [
  { title: "ID", key: "id" },
  {
    title: "Name",
    key: "name",
    render: (name, row) => (
      <div className="flex items-center">
        <Avatar
          src={row.profile}
          className="border-2 border-primary mr-2 w-10 h-10"
        />
        <p className="text-primary capitalize">{name}</p>
      </div>
    ),
  },
  { title: "Position", key: "position" },
  { title: "Department", key: "department" },
  { title: "Rate", key: "rate" },
  { title: "Address", key: "address" },
  { title: "Contact", key: "contact" },
  { title: "Marital Status", key: "maritalStatus" },
  { title: "Gender", key: "gender" },
];

const dummyData = [
  {
    id: "1",
    profile: "profile1.jpg", // Replace with actual profile image path
    employeeName: "John Doe",
    formType: "Verbal Warning",
    incidentDate: "2024-11-20",
    reportedBy: "Jane Smith",
    department: "HR",
    description: "Late arrivals for 3 consecutive days",
    actionTaken: "Verbal warning issued",
    status: "Resolved",
  },
  {
    id: "2",
    profile: "profile2.jpg",
    employeeName: "Emily Davis",
    formType: "Written Warning",
    incidentDate: "2024-10-10",
    reportedBy: "Michael Johnson",
    department: "IT",
    description: "Inappropriate use of company resources",
    actionTaken: "Written warning issued",
    status: "Pending",
  },
  {
    id: "3",
    profile: "profile3.jpg",
    employeeName: "Chris Brown",
    formType: "Final Warning",
    incidentDate: "2024-09-15",
    reportedBy: "Sarah Wilson",
    department: "Finance",
    description: "Failure to meet project deadlines",
    actionTaken: "Scheduled performance review",
    status: "Escalated",
  },
  {
    id: "4",
    profile: "profile4.jpg",
    employeeName: "David Lee",
    formType: "Suspension",
    incidentDate: "2024-08-05",
    reportedBy: "James Anderson",
    department: "Operations",
    description: "Violation of safety protocols",
    actionTaken: "Suspension for 3 days",
    status: "Resolved",
  },
  {
    id: "5",
    profile: "profile5.jpg",
    employeeName: "Olivia Harris",
    formType: "Termination",
    incidentDate: "2024-07-20",
    reportedBy: "Laura Martinez",
    department: "Support",
    description: "Breach of confidentiality agreement",
    actionTaken: "Employment terminated",
    status: "Resolved",
  },
];

const DevicesMain = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const transactionType = useSelector((state) => state.transactionType);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationSelected, setLocationSelected] = useState("");
  const [departmentSelected, setDepartmentSelected] = useState("");

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleLocationChange = (value) => setLocationSelected(value);
  const handleDepartmentChange = (value) => setDepartmentSelected(value);

  const filteredData = useMemo(() => {
    return dummyData.filter((record) => {
      const matchesLocation = locationSelected
        ? record.location === locationSelected
        : true;
      const matchesDepartment = departmentSelected
        ? record.department === departmentSelected
        : true;
      const matchesSearch = record.employeeName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      return record && matchesLocation && matchesDepartment && matchesSearch;
    });
  }, [searchTerm, locationSelected, departmentSelected]);

  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        // router(`/Devices/DeviceDetails`);
        break;
      case "edit":
        console.log(`Edit details for employee ID: ${id}`);
        // router(`/Devices/1`);

        break;
      case "remove":
        // console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  // Action menu for each row
  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleActionEmployee("view", id)}>
        View
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleAction("edit", id)}>
        Issue Discipilnary
      </Menu.Item>
      {/* <Menu.Item key="3" onClick={() => handleAction("remove", id)}>
        Remove
      </Menu.Item> */}
    </Menu>
  );

  const handleActionEmployee = (action, id) => {
    switch (action) {
      case "view":
        router(`/EmployeeDetails`);
        break;
      case "edit":
        router(`/Employee/1`);
        console.log(`Edit details for employee ID: ${id}`);
        break;
      case "remove":
        console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  const Tabs = [
    {
      key: "program",
      label: "Discipline",
      component: (
        <AttendanceTable
          columns={columnHeaders}
          data={filteredData}
          pageSize={10}
          //   actions={actionMenu}
        />
      ),
    },
    {
      key: "general",
      label: "Employees",
      component: (
        <EmployeTable
          columns={columns}
          data={EmployeeData}
          pageSize={5}
          onAction={handleActionEmployee}
          actions={actionMenu}
        />
      ),
    },
  ];

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: "Tardiness",
          onClick: () => setShowForm(true),
        },
        {
          key: "2",
          label: "Absent",
          onClick: () => setShowForm(true),
        },
        {
          key: "3",
          label: "Verbal Abuse",
          onClick: () => setShowForm(true),
        },
        {
          key: "4",
          label: "Sexual Harassment",
          onClick: () => setShowForm(true),
        },
        {
          key: "5",
          label: "Productivity",
          onClick: () => setShowForm(true),
        },
        {
          key: "6",
          label: "Discrimination",
          onClick: () => setShowForm(true),
        },
        {
          key: "7",
          label: "Drug & Alcohol Abuse",
          onClick: () => setShowForm(true),
        },
        {
          key: "8",
          label: "Health Violation",
          onClick: () => setShowForm(true),
        },
        {
          key: "9",
          label: "Stolen Property",
          onClick: () => setShowForm(true),
        },
      ]}
    />
  );

  return (
    <div className="h-full p-4">
      <div className="flex w-full justify-between">
        <h2 className="text-xl font-semibold">Awards</h2>
        <Dropdown overlay={menu} trigger={["hover"]} placement="bottomLeft">
          <Button className="rounded-md flex items-center">
            Add Discipline Form
            {/* <DownOutlined className="ml-2" /> */}
          </Button>
        </Dropdown>
      </div>
      <div style={{ margin: "20px 0px 20px 0px" }}>
        <DisciplineCards />
      </div>

      {/* <div className="grid gap-4 grid-cols-5 mb-4 mt-5">
        <Input
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full h-[32px]"
        />
        <Select
          placeholder="Location"
          onChange={handleLocationChange}
          className="w-full h-[32px]"
          allowClear
        >
          {Array.from(new Set(dummyData.map((data) => data.location))).map(
            (location) => (
              <Option key={location} value={location}>
                {location}
              </Option>
            )
          )}
        </Select>
        <Select
          placeholder="Department"
          onChange={handleDepartmentChange}
          className="w-full h-[32px]"
          allowClear
        >
          {Array.from(new Set(dummyData.map((data) => data.department))).map(
            (department) => (
              <Option key={department} value={department}>
                {department}
              </Option>
            )
          )}
        </Select>
      </div> */}

      <div className=" sm:px-12 mt-6 sm:mt-7 m-5  flex items-center justify-center">
        <div className="grid grid-cols-2 gap-0 ">
          {Tabs.map((tab) => (
            <button
              className={`${
                tab.key === transactionType
                  ? "border-primary bg-primary font-semibold text-white rounded-t-lg"
                  : "text-[#B7B7B7] bg-[#ECECEC] font-semibold rounded-tr-lg"
              }  p-2  text-[0.9rem] shadow-md flex items-center justify-center px-16 `}
              onClick={() =>
                dispatch({ type: "TRANSACTION_TYPE", num: tab.key })
              }
            >
              {tab.label}
            </button>
          ))}
        </div>{" "}
      </div>
      <div
        className={`${
          transactionType === "general"
            ? "hidden"
            : "grid gap-4 grid-cols-5 mb-4 mt-5"
        }`}
      >
        <Input
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full h-[32px]"
        />
        {/* <Select
          placeholder="Location"
          onChange={handleLocationChange}
          className="w-full h-[32px]"
          allowClear
        >
          {Array.from(new Set(dummyData.map((data) => data.location))).map(
            (location) => (
              <Option key={location} value={location}>
                {location}
              </Option>
            )
          )}
        </Select> */}
        <Select
          placeholder="Department"
          onChange={handleDepartmentChange}
          className="w-full h-[32px]"
          allowClear
        >
          {Array.from(new Set(dummyData.map((data) => data.department))).map(
            (department) => (
              <Option key={department} value={department}>
                {department}
              </Option>
            )
          )}
        </Select>
      </div>
      <div className="mt-6">
        {Tabs.find((tab) => tab.key === transactionType)?.component}
      </div>
      {showForm && <Disciplineform setShow={setShowForm} />}
    </div>
  );
};

export default DevicesMain;
