import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import across from "../../../../imgs/across.png";
import SignatureWhite from "../../../../imgs/signatureWhite.png";
import SignatureBlack from "../../../../imgs/signatureBlack.png";
import fingerprintWhite from "../../../../imgs/fingerprintWhite.png";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import codeHandler from "../../../Register/codeHandler/codehandle";
import { Button } from "antd";
let file;
let data;
const EmployerE = () => {
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const [test, setTest] = useState(true);
  const [employer, setEmployer] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [position, setPosition] = useState("");
  const [location, setLocation] = useState("");
  const [show, setShow] = useState("");
  const [err, setErr] = useState("");
  const [signature, setSignature] = useState("");
  const veri = useSelector((state) => state.verify);
  const [sig, setSig] = useState(true);
  const [sigD, setSigD] = useState(false);
  const [gal, setGal] = useState(false);
  const [pop, setPop] = useState(false);
  const [display, setDisplay] = useState("");
  const token = useSelector((state) => state.token);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, [data]);
  useEffect(() => {
    if (profileData.employeeInfo) {
      data = profileData.employeeInfo;
      setEmployer(data.employer);
      setSupervisor(data.supervisor);
      setPosition(data.role);
      setLocation(data.mhsssFacility?.name);
      setSignature(data?.signature);
      const body = [data?.signature];
      if (data?.signature) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            setDisplay(data.data[0].url);
          })
          .catch(() => {});
      }
    }
  }, [profileData]);

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    // if (signature && veri) {
    //   body.signature = signature;
    // }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/v1/employee-info`,
        {
          employer,
          supervisor,
          position,
          // departmentOrLocation: location,
          mhsssfacilityId: location,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
          },
        }
      )
      .then(() => {
        dispatch({ type: "RESET" });
      })
      .catch((err) => {
        setErr(err.response.data.message);
        setShow(true);
      });
  };

  const downloadFile = () => {
    const url = "https://sap-api-staging.jabcomp.gy/public/biometric-sdk.exe";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "biometric-sdk.exe");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }

  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setDisplay("");
      })
      .catch(function () {});
  }

  const imageHandler = (setState, setUrl, load) => async (e) => {
    setPop(false);
    setSigD(false);
    load(true);
    file = e.target.files[0];
    console.log("Fileee", file);
    if (file === undefined) {
      // setIsLoading(false);
      // setIsLoading1(false);
      // setIsLoading2(false);
      // setIsLoading3(false);
      // setIsLoading4(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      console.log("base", base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          setGal(true);
          load(false);
        })
        .catch(() => {});
    }
  };

  const imageHandlerSig = (file) => async () => {
    console.log("OKKKK");
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setSignature(data.data.url);
      })
      .catch(() => {});
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  return (
    <div>
      <form onSubmit={submitHandler}>
        {/* <div className="flex items-center justify-center flex-col">
          <h2
            className={`
             text-[0.9rem]
             text-center mb-6 text-[#F25A29] font-semibold `}
          >
            Please upload at least one, Signature or one Fingerprint
          </h2>
          <div className="sm:w-full flex flex-col items-center justify-center">
            <div className="w-full flex items-center justify-center gap-12">
              {veri && (
                <div
                  onClick={() => {
                    setSig(true);
                  }}
                  className={`w-[4rem] h-[4rem] ${
                    veri ? "bg-primary" : "bg-white"
                  } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
                >
                  <img src={veri ? SignatureWhite : SignatureBlack} alt="" />
                </div>
              )}
              {!veri && (
                <div
                  onClick={() => {
                    setSig(false);
                  }}
                  className={`w-[4rem] h-[4rem] ${
                    !veri ? "bg-primary" : "bg-white"
                  } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
                >
                  <img
                    src={!veri ? fingerprintWhite : fingerprintWhite}
                    alt=""
                  />
                </div>
              )}
            </div>
            {gal && veri ? (
              <h2
                className={`
             text-[0.9rem]
             text-center mb-2 mt-2 text-[#F25A29] font-semibold `}
              >
                Uploaded from gallery
              </h2>
            ) : (
              ""
            )}
            {veri && (
              <div
                className={`border-dashed bg-white mt-4 border-2 border-primary flex items-center justify-center rounded-lg text-center sm:w-[23rem] sm:h-[16rem] w-[18rem] h-[13rem] relative`}
              >
                {display ? (
                  <>
                    <img
                      src={display}
                      alt="Uploaded"
                      className="w-full h-full"
                    />
                    <button
                      className="absolute bottom-1 right-1"
                      onClick={() => {
                        setSignature("");
                        setDisplay("");
                        setGal(false);
                      }}
                    >
                      <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                    </button>
                  </>
                ) : (
                  <div className="w-[80%] flex flex-col gap-1 flex items-center justify-center">
                    <div className="flex items-center justify-center">
                      <label
                        className="text-5xl text-primary cursor-pointer"
                      >
                        <UploadOutlined />
                      </label>
                     
                    </div>
                    <p>Upload Signature or Thumbprint Here</p>
                    <Button
                      onClick={() => {
                        setPop(true);
                      }}
                      className="mt-4 w-[80%] cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg"
                    >
                      Upload
                    </Button>
                  </div>
                )}
              </div>
            )}
            {sigD && veri && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    GetWhitSigPic();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Open Pad
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ConfirmSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Get Sign
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ReSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Reset
                </button>
              </div>
            )}
            {sigD && veri && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <p
                  onClick={downloadFile}
                  className="text-primary hover:underline cursor-pointer font-semibold"
                >
                  Download SDK
                </p>
              </div>
            )}
          </div>
        </div> */}
        <h2 className="mb-4 text-[1.1rem] font-semibold">Employer:</h2>
        <input
          disabled
          type="text"
          name=""
          placeholder="Employer"
          value={employer}
          onChange={handleChange(setEmployer)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Supervisor:</h2>
        <input
          disabled
          type="text"
          name=""
          placeholder="Supervisor"
          value={supervisor}
          onChange={handleChange(setSupervisor)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Role:</h2>
        <input
          disabled
          type="text"
          name=""
          placeholder="Position"
          value={position}
          onChange={handleChange(setPosition)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 capitalize "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">
          Department/Location:
        </h2>
        <input
          disabled
          type="text"
          name=""
          placeholder="Department/Location"
          value={location}
          onChange={handleChange(setLocation)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Signature:</h2>
        <div
          className={`${display ? "border-dashed bg-white mt-4 border-2 border-primary flex items-center justify-center rounded-lg text-center sm:w-[23rem] sm:h-[16rem] w-[18rem] h-[13rem] relative" :"hidden"}`}
        >
          <>
            <img src={display} alt="Uploaded" className="w-full h-full" />
            {/* <button
              className="absolute bottom-1 right-1"
              onClick={() => {
                setSignature("");
                setDisplay("");
                setGal(false);
              }}
            >
              <DeleteFilled className="text-2xl text-primary cursor-pointer " />
            </button> */}
          </>
        </div>

        {/* <button
          onClick={submitHandler}
          className={`${
            profileData.user.status === "pending" &&
            dash === "/Dashboard/PensionerAccount"
              ? "hidden"
              : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          /><span>Save</span>
          <span>Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoadingx ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button> */}
      </form>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployerE;
