import React from "react";
import pencil from "../../../../imgs/pencil.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import { Switch } from "antd";
const SpecificPriceTab = ({
  data,
  setShow,
  setErr,
  setRefetch,
  refetch,
  setShowSuccessModal,
  setShowErrorModal,
}) => {
  const token = useSelector((state) => state.token);
  const ChangeStatus = (id, isActive) => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-payment-terms/${id}/change-status`,
        { isActive: !isActive },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setErr(`Status Changed Successfully`);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  const TierCard = ({ item }) => {
    return (
      <div className="  p-4 rounded-lg bg-[#EEEDED] shadow relative">
        <div className="flex justify-between items-center">
          <h3 className="text-gray-800 font-semibold capitalize text-[1rem]">
            {formatName(item?.method)}
          </h3>

          <div className=" top-2 right-3 flex gap-3">
            <Switch
              // checkedChildren="Whitelist"
              // unCheckedChildren="Blacklist"
              className="absolute top-4 right-4"
              size="small"
              style={{
                backgroundColor:
                  item?.isActive === true ? "#1BA397" : "#c3c6c9",
              }}
              checked={item?.isActive === true}
              onClick={() => {
                ChangeStatus(item?.id, item?.isActive);
              }}
            />
          </div>
        </div>

        <div className=" text-gray-700">
          <span className={`${item?.downPayPercent ? "" : "hidden"}`}>
            Down Payment- {item?.downPayPercent}%
          </span>
          <span className={`${item?.netDays ? "" : "hidden"}`}>
            Credit duration- {item?.netDays} Days
          </span>
          <p className={`${item?.buyerProgram ? "" : "hidden"}`}>
            Program-{" "}
            <span className="text-primary font-semibold text-[0.95rem]">
              {formatProgramNames(item?.buyerProgram)}
            </span>
          </p>
          <p className="text-gray-500 text-[0.76rem]">
            Full payment is due at the time of delivery.
          </p>
        </div>
      </div>
    );
  };
  const formatName = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const formatProgramNames = (data) => {
    if (data === "plwd") return "PLWD";
    else if (data === "pa-medical") return "PA Medical";
    else if (data === "pa-economical") return "PA Economical";
    else
      return data
        .replace(/-/g, " ") // Replace hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };
  return (
    <div className="p-6 ">
      <div className="grid md:grid-cols-3 gap-5 sm:grid-cols-2 grid-cols-1">
        {data?.map((item, index) => (
          <TierCard key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default SpecificPriceTab;
