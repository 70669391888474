import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Cards from "./listing";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ListingGROAdmin = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const user = useSelector((state) => state.user);
  const dataUser = useSelector((state) => state.dataUser);
  const eventCreator = useSelector((state) => state.eventCreator);
  console.log(dataUser);
  const userId = dataUser?.data?.user
    ? dataUser?.data?.user.id
    : dataUser?.data?.merchuser?.id;

  return (
    <div className="flex items-center justify-center pt-[2rem] flex-col">
      <div
        className={`${
          user === "merchant-owner" ? "sm:w-[90%] w-full" : "sm:w-[80%] w-full"
        }  flex flex-col items-center justify-center`}
      >
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                if (user === "merchant-owner") {
                  if (dash === "/MerchantDashBoard/Subevents") {
                    router("/Merchant/EventDetail");
                  } else {
                    router("/Merchant/dashboard");
                  }
                  // router("/Merchant/dashboard");
                  // return;
                } else if (dash === "/EmployeeDashBoard/Subevents") {
                  router("/EventDetails");
                } else if (dash === "/EmployeeDashBoard/FavoriteEvents") {
                  router("/EmployeeDashBoard/events");
                } else if (dash === "/PensionerDashBoard/Subevents") {
                  router("/EventDetails");
                } else {
                  if (user === "superadmin") router("/superAdminDashboard");
                  else if (user === "admin") router("/adminDashboard");
                  else if (user === "employee") router("/EDashboard");
                  else router("/Dashboard");
                }
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                {dash === "/EmployeeDashBoard/Subevents" ||
                dash === "/PensionerDashBoard/Subevents" ||
                dash === "/MerchantDashBoard/Subevents"
                  ? "Sub Events"
                  : dash === "/EmployeeDashBoard/FavoriteEvents"
                  ? "Saved Events"
                  : "Events"}
              </h2>
            </div>

            {(dash === "/EmployeeDashBoard/events" ||
              dash === "/PensionerDashBoard/events" ||
              dash === "/MerchantDashBoard/events") && (
              <button
                onClick={() => {
                  if (user === "merchant-owner") {
                    router("/Merchant/addEvent");
                    return;
                  }
                  router("/addEvent");
                }}
                className={`${
                  dataUser?.data?.user?.status === "pending"
                    ? "pointer-events-none"
                    : ""
                } text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
              >
                Create Event
              </button>
            )}
            {(dash === "/EmployeeDashBoard/Subevents" ||
              dash === "/PensionerDashBoard/Subevents" ||
              dash === "/MerchantDashBoard/Subevents") && (
              <button
                onClick={() => {
                  if (user === "merchant-owner") {
                    router("/Merchant/addSubEvent");
                    return;
                  }
                  router("/addSubEvent");
                }}
                className={`${
                  dataUser?.data?.user?.status === "pending"
                    ? "pointer-events-none"
                    : ""
                } text-white ${
                  eventCreator?.id === userId ? "" : "hidden"
                } bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
              >
                Create Sub Event
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${
          user === "merchant-owner"
            ? "sm:w-[90%] w-full p-2"
            : "sm:w-[80%] w-full p-2"
        }`}
      >
        <Cards />
      </div>
    </div>
  );
};

export default ListingGROAdmin;
