import axios from "axios";
import { Select } from "antd";
import { useEffect, useState } from "react";
import across from "../../imgs/across.png";
import SignatureWhite from "../../imgs/signatureWhite.png";
import SignatureBlack from "../../imgs/signatureBlack.png";
import fingerprintWhite from "../../imgs/fingerprintWhite.png";
import gallary from "../../imgs/picture.png";
import device from "../../imgs/sign.png";
import figA from "../../imgs/fingerActive.png";
import { useDispatch, useSelector } from "react-redux";
import codeHandler from "../Register/codeHandler/codehandle";
import { Button } from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
let file;
const EmployeeInfo = () => {
  const [employer, setEmployer] = useState(
    "Ministry of Human Services & Social Services Guyana"
  );
  const [supervisor, setSupervisor] = useState("");
  const [role, setRole] = useState("");
  const [location, setLocation] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [check, setCheck] = useState(true);
  const token = useSelector((state) => state.token);
  const [department, setDepartment] = useState([]);
  const dispatch = useDispatch();
  const { Option } = Select;
  const [signature, setSignature] = useState("");
  const veri = useSelector((state) => state.verify);
  const [sig, setSig] = useState(true);
  const [sigD, setSigD] = useState(false);
  const [gal, setGal] = useState(false);
  const [pop, setPop] = useState(false);
  const [display, setDisplay] = useState("");

  //Get deparmtnet locations and warehouse
  useEffect(() => {
    const api = "v1/mhsss-facility";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        const filteredData = data?.data?.rows.filter(
          (item) => item.type === "department"
        );
        // const dep = filteredData?.map((dep) => ({
        //   label:
        //     dep?.name +
        //     "" +
        //     (dep?.type === "master-warehouse"
        //       ? "    (Master Warehouse)"
        //       : `    (${
        //           dep?.type.charAt(0).toUpperCase() + dep?.type.slice(1)
        //         }) `),
        //   value: dep?.id,
        // }));
        // console.log("dep", dep);
        setDepartment(filteredData);
      })

      .catch(() => {});
  }, []);
  const nextHandler = (e) => {
    if (supervisor === "") {
      setErr("Supervisor is not Added");
      setShow(true);
      setCheck(false);
    }
    // else if (role==="") {
    //   setErr("Role is not selected");
    //   setShow(true)
    //   setCheck(false)
    // }
    else if (location === "") {
      setErr("Department is not selected");
      setShow(true);
      setCheck(false);
    } else if (signature === "") {
      setErr("Please upload your signature");
      setShow(true);
      setCheck(false);
    } else {
      setCheck(true);
    }
    e.preventDefault();
    var body;
    if (check) {
      if (role === "") {
        body = {
          employer,
          supervisor,
          mhsssfacilityId: `${location}`,
          signature,
        };
      } else {
        body = {
          employer,
          supervisor,
          role,
          mhsssfacilityId: `${location}`,
          signature,
        };
      }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/employee-info`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch({ type: "IINCREMENT" });
          dispatch({ type: "POP" });
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const downloadFile = () => {
    const url = "https://sap-api-staging.jabcomp.gy/public/biometric-sdk.exe";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "biometric-sdk.exe");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }

  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setDisplay("");
      })
      .catch(function () {});
  }

  const imageHandler = (setState, setUrl, load) => async (e) => {
    setPop(false);
    setSigD(false);
    // load(true);
    file = e.target.files[0];
    console.log("Fileee", file);
    if (file === undefined) {
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      console.log("base", base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          setGal(true);
          load(false);
        })
        .catch(() => {});
    }
  };

  const imageHandlerSig = (file) => async () => {
    console.log("OKKKK");
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setSignature(data.data.url);
      })
      .catch(() => {});
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const options = department?.map((item) => ({
    value: item?.id,
    label: item?.name,
    address: [
      item?.lot,
      item?.address,
      item?.regionNo,
      item?.regionName,
      item?.city,
      item?.ward,
    ]
      .filter(Boolean)
      .join(", "),
  }));

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex flex-col">
        <h1 className="font-semibold text-gray-700 text-[0.95rem]">
          {option?.label}
        </h1>
        <p className="text-sm text-gray-500">{option?.address}</p>
      </div>
    </Option>
  );
  return (
    <div className="flex justify-center">
      <form
        action=""
        className="sm:w-[50%] w-[85%] mt-10"
        onSubmit={nextHandler}
      >
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Employer:
        </h2>
        <input
          type="text"
          name=""
          placeholder="Employer"
          onChange={handleChange(setEmployer)}
          value={employer}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Supervisor:
        </h2>
        <input
          type="text"
          name=""
          onChange={handleChange(setSupervisor)}
          value={supervisor}
          placeholder="Supervisor"
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Roles/Position:
        </h2>
        <div>
          <Select
            placeholder="Select Role/Position"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
            }}
            onChange={(val) => setRole(val)}
            // filterOption={(input, option) =>
            //   (option?.label ?? "")
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            size="middle"
            options={[
              {
                value: "chief probation social services officer",
                label: "Chief Probation Social Services Officer",
              },
              {
                value: "customer service",
                label: "Customer Service",
              },
              {
                value: "clerk",
                label: "Clerk",
              },
              {
                value: "manager",
                label: "Manager",
              },

              {
                value: "reviewing officer",
                label: "Reviewing Officer",
              },
              {
                value: "regional director",
                label: "Regional Director",
              },
              {
                value: "social worker",
                label: "Social Worker",
              },

              {
                value: "director of social services",
                label: "Director of Social Services",
              },
              {
                value: "senior manager",
                label: "Senior Manager",
              },
              {
                value: "staff",
                label: "Staff",
              },
            ]}
          />
        </div>
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Department/Location:
        </h2>
        <div>
          {/* <Select
            placeholder="Department/Location"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
            }}
            onChange={(val) => setLocation(val)}
            // filterOption={(input, option) =>
            //   (option?.label ?? "")
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            size="middle"
            options={department}
            value={location}
          /> */}

          <Select
            placeholder="Department/Location"
            // className="wow w-[100%] mb-4"
            className="w-[100%] woww mb-4"
            renderOption={renderOption}
            onChange={(value) => {
              setLocation(value);
            }}
            value={location !== "" ? location : null}
          >
            {options?.map(renderOption)}
          </Select>
        </div>
        <div className="flex items-center justify-center flex-col">
          <h2
            className={`
             text-[0.9rem]
             text-center mb-6 text-[#F25A29] font-semibold `}
          >
            Please upload Signature
          </h2>
          <div className="sm:w-full flex flex-col items-center justify-center">
            <div className="w-full flex items-center justify-center gap-12">
              {veri && (
                <div
                  onClick={() => {
                    setSig(true);
                  }}
                  className={`w-[4rem] h-[4rem] ${
                    veri ? "bg-primary" : "bg-white"
                  } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
                >
                  <img src={veri ? SignatureWhite : SignatureBlack} alt="" />
                </div>
              )}
              {!veri && (
                <div
                  onClick={() => {
                    setSig(false);
                  }}
                  className={`w-[4rem] h-[4rem] ${
                    !veri ? "bg-primary" : "bg-white"
                  } p-2 shadow-lg rounded-md cursor-pointer flex items-center justify-center`}
                >
                  <img
                    src={!veri ? fingerprintWhite : fingerprintWhite}
                    alt=""
                  />
                </div>
              )}
            </div>
            {gal && veri ? (
              <h2
                className={`
             text-[0.9rem]
             text-center mb-2 mt-2 text-[#F25A29] font-semibold `}
              >
                Uploaded from gallery
              </h2>
            ) : (
              ""
            )}
            {veri && (
              <div
                className={`border-dashed bg-white mt-4 border-2 border-primary flex items-center justify-center rounded-lg text-center sm:w-[23rem] sm:h-[16rem] w-[18rem] h-[13rem] relative`}
              >
                {display ? (
                  <>
                    <img
                      src={display}
                      alt="Uploaded"
                      className="w-full h-full"
                    />
                    <button
                      className="absolute bottom-1 right-1"
                      onClick={() => {
                        setSignature("");
                        setDisplay("");
                        setGal(false);
                      }}
                    >
                      <DeleteFilled className="text-2xl text-primary cursor-pointer " />
                    </button>
                  </>
                ) : (
                  <div className="w-[80%] flex flex-col gap-1 flex items-center justify-center">
                    {/* <UploadOutlined className="text-5xl text-primary cursor-pointer " /> */}
                    <div className="flex items-center justify-center">
                      <label
                        // htmlFor="file-upload"
                        className="text-5xl text-primary cursor-pointer"
                      >
                        <UploadOutlined />
                      </label>
                      {/* <input
                      id="file-upload"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={imageHandler(
                        setDisplay,
                        setSignature,
                        setIsLoading
                      )}
                    /> */}
                    </div>
                    <p>Upload Signature </p>
                    <Button
                      onClick={() => {
                        setPop(true);
                      }}
                      className="mt-4 w-[80%] cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg"
                    >
                      Upload
                    </Button>
                  </div>
                )}
              </div>
            )}
            {sigD && veri && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <button
                  type="button"
                  onClick={() => {
                    GetWhitSigPic();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Open Pad
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ConfirmSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Get Sign
                </button>
                <button
                  type="button"
                  onClick={() => {
                    ReSign();
                  }}
                  className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
                >
                  Reset
                </button>
              </div>
            )}
            {sigD && veri && (
              <div
                className="flex gap-5 sm:w-[23rem] w-[18rem]"
                style={{
                  padding: "10px",
                  textAlign: "center",
                  display: "inline-block",
                }}
              >
                <p
                  onClick={downloadFile}
                  className="text-primary hover:underline cursor-pointer font-semibold"
                >
                  Download SDK
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
          <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button>
          <button
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Register
          </button>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </form>
      <div
        className={`${
          pop ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <h1 className="text-xl text-primary font-semibold">Choose</h1>
          <div className="w-[80%] flex items-center justify-center mt-10 mb-10 gap-6">
            {/* <img
              onClick={ConfirmSig}
              src={figA}
              alt=""
              className="w-14 h-14 cursor-pointer"
            /> */}
            <img
              onClick={() => {
                setSigD(true);
                setPop(false);
              }}
              src={device}
              alt=""
              className="w-18 h-14 cursor-pointer"
            />
            <label>
              <img src={gallary} alt="" className="w-14 h-14 cursor-pointer" />
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={imageHandler(setDisplay, setSignature)}
              />
            </label>
          </div>
          <div className="flex items-center justify-end w-[80%]">
            <button
              onClick={() => setPop(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfo;
