import React from "react";
import DisciplineForm from "./form";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import activeDisc from "../../../../imgs/logo12.png";
import ErrorModal from "../../../Merchant/Modals/errorModal";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { TextBox } from "@react-pdf-viewer/core";
import { Select } from "antd";

const Disciplineform = ({ setShow, title }) => {
  const [showError, setShowError] = useState("");
  const [err, setErr] = useState("");
  const [formData, setFormData] = useState({
    date: "",
    // supervisorFirstName: "",
    // supervisorLastName: "",
    supervisorEmail: "",
    employeeName: null,
    reason: "",
    disciplinaryAction: "",
    deficiency: "",
    signature: "",
    directorSignature: "",
  });

  const signaturePad = useRef(null);
  const directorSignaturePad = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signaturePad.current.clear();
    setFormData({
      ...formData,
      signature: "",
    });
  };

  const clearDirectorSignature = () => {
    directorSignaturePad.current.clear();
    setFormData({
      ...formData,
      directorSignature: "",
    });
  };

  // Update signature in form data when user signs
  const handleEnd = () => {
    const signatureData = signaturePad.current.toDataURL();
    setFormData({
      ...formData,
      signature: signatureData,
    });
  };

  const handleEndDirector = () => {
    const signatureData = directorSignaturePad.current.toDataURL();
    setFormData({
      ...formData,
      directorSignature: signatureData,
    });
  };

  // Define styles
  const styles = StyleSheet.create({
    page: { padding: 30, display: "grid" },
    section: {
      marginBottom: 10,
    },
    title: { fontSize: 24, textAlign: "center", marginBottom: 25 },
    label: {
      fontWeight: "extrabold",
      fontSize: 12,
      marginBottom: 6,
      color: "#101010",
    },
    input: {
      fontSize: 10.5,
      padding: 8,
      marginBottom: 10,
      border: "1px solid #ddd",
      borderRadius: 5,
      color: "#484848",
    },
    textarea: {
      fontSize: 10.5,
      padding: 8,
      border: "1px solid #ddd",
      borderRadius: 5,
      color: "#484848",
    },
    signatureContainer: { marginBottom: 20 },
    signatureCanvas: { border: "1px solid #ddd" },
    button: {
      padding: 10,
      backgroundColor: "#256091",
      color: "#fff",
      borderRadius: 5,
      textAlign: "center",
    },
    logo: {
      position: "absolute",
      top: 10,
      left: 40,
      width: 60,
      height: 60,
    },
    agreementContainer: { marginTop: 15 },
    agreementText: { fontSize: 12, marginBottom: 10 },
    agreementItem: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    checkbox: {
      fontSize: 4,
      marginRight: 5,
      border: "1px solid #ddd",
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 4,
      paddingBottom: 4,
      borderRadius: 3,
      backgroundColor: "#1BA397",
      color: "white",
    },
    checkboxText: { fontSize: 11 },
    signatureLabel: {
      fontWeight: "normal",
      fontSize: 10,
      color: "red",
    },
  });

  // Create PDF Document Component
  const MyDocument = ({ formData }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Add Logo */}
        <Image src={activeDisc} style={styles.logo} />

        <Text style={styles.title}>Coaching and/or Verbal Warning</Text>
        <View>
          <TextBox>Coaching and/or Verbal Warning</TextBox>
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>Date</Text>
          <Text style={styles.input}>{formData.date}</Text>
        </View>
        {/* <View style={styles.section}>
          <Text style={styles.label}>Field Supervisor Name</Text>
          <Text style={styles.input}>
            First Name: {formData.supervisorFirstName}
          </Text>
          <Text style={styles.input}>
            Last Name: {formData.supervisorLastName}
          </Text>
        </View> */}
        <View style={styles.section}>
          <Text style={styles.label}>Supervisor Email Address</Text>
          <Text style={styles.input}>{formData.supervisorEmail}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Employee Name</Text>
          <Text style={styles.input}>{formData.employeeName}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Reason</Text>
          <Text style={styles.textarea}>{formData.reason}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.checkboxText}>
            <Text style={styles.label}>Coaching- </Text>I have reviewed the
            policies and procedures with the employee and they understand and
            agree to follow said policies and procedures.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.checkboxText}>
            <Text style={styles.label}>Verbal Warning- </Text> Employee has had
            previous coaching sessions and is still failing to follow the
            policies and procedures.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Type of Disciplinary Action</Text>
          <Text style={styles.input}>{formData.disciplinaryAction}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Nature of Deficiency</Text>
          <Text style={styles.textarea}>{formData.deficiency}</Text>
        </View>

        {/* Agreement Section */}
        <View style={styles.section}>
          <Text style={styles.label}>Agreement</Text>
          <View style={styles.agreementContainer}>
            <Text style={styles.agreementText}>
              I, undersigned, agree with the following statements:
            </Text>
            <View style={styles.agreementItem}>
              <Text style={styles.checkbox}>
                {formData.isChecked1 ? "✓" : "☐"}
              </Text>
              <Text style={styles.checkboxText}>
                I have, on this date, spoken to the employee stating that the
                above conduct is not acceptable, and if repeated, will become
                the basis for formal disciplinary action.
              </Text>
            </View>
            <View style={styles.agreementItem}>
              <Text style={styles.checkbox}>☐</Text>
              <Text style={styles.checkboxText}>
                I have also informed said employee that the imposition of
                disciplinary action, up to and including termination, is not
                pre-conditioned upon receipt of verbal or written notice of
                unacceptable conduct.
              </Text>
            </View>
          </View>
        </View>
        {/* Signatures */}
        <View style={styles.signatureContainer}>
          <Text style={styles.label}>
            Authorized Signature{" "}
            <Text style={styles.signatureLabel}>( Tom Jones, Manager )</Text>
          </Text>
          <View style={styles.signatureCanvas}>
            {formData.signature ? (
              <Image
                style={{ width: 250, height: 100 }}
                src={formData.signature}
              />
            ) : (
              <Text>(Signature Here)</Text>
            )}
          </View>
        </View>

        <View style={styles.signatureContainer}>
          <Text style={styles.label}>
            Authorized Signature{" "}
            <Text style={styles.signatureLabel}>
              ( R. C. Chung - Director of Human Resources )
            </Text>
          </Text>
          <View style={styles.signatureCanvas}>
            {formData.directorSignature ? (
              <Image
                style={{ width: 250, height: 100 }}
                src={formData.directorSignature}
              />
            ) : (
              <Text>( Director Signature Here)</Text>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );

  const validateForm = () => {
    const {
      date,
      supervisorFirstName,
      supervisorLastName,
      supervisorEmail,
      employeeName,
    } = formData;
    if (
      !date ||
      !supervisorFirstName ||
      !supervisorLastName ||
      !supervisorEmail ||
      !employeeName
    ) {
      setErr("Please fill in all required fields.");
      setShowError(true);
      return false;
    }
    return true;
  };

  const handleOpenPDF = async () => {
    if (
      !formData?.date ||
      // !formData?.supervisorFirstName ||
      // !formData?.supervisorLastName ||
      !formData?.supervisorEmail ||
      !formData?.employeeName ||
      !formData?.reason ||
      !formData?.disciplinaryAction ||
      !formData?.deficiency
    ) {
      setErr("Please fill in all fields before generating the PDF.");
      setShowError(true);

      return;
    }
    if (!formData.signature) {
      setErr("Please add a signature before generating the PDF.");
      setShowError(true);
      return;
    }
    const doc = <MyDocument formData={formData} />;
    const blob = await pdf(doc).toBlob();
    const pdfURL = URL.createObjectURL(blob);
    window.open(pdfURL, "_blank");
  };
  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-2xl max-h-[90%] bg-white rounded-lg shadow dark:bg-gray-700 overflow-hidden">
        {/* Modal Header */}
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Add Discipline
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={() => setShow(false)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        {/* Modal Body */}
        <div className="space-y-4 overflow-y-auto max-h-[60vh] bg-gray-100">
          {/* <DisciplineForm  /> */}
          <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
              <h2 className="text-2xl font-bold text-center mb-6">
                Coaching and/or Verbal Warning
              </h2>
              <form>
                {/* Date Field */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Date</label>
                  <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Field Supervisor Name */}
                {/* <label className="block text-sm font-medium mb-2">
                  Field Supervisor Name
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <input
                      type="text"
                      name="supervisorFirstName"
                      value={formData.supervisorFirstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="supervisorLastName"
                      value={formData.supervisorLastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div> */}

                {/* Supervisor Email Address */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      Supervisor Email Address
                    </label>
                    <input
                      type="email"
                      name="supervisorEmail"
                      value={formData.supervisorEmail}
                      onChange={handleChange}
                      placeholder="Email Address"
                      className="w-full border border-gray-300 rounded-lg p-2.5 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">
                      Select Employee
                    </label>
                    <Select
                      name="employeeName"
                      value={formData.employeeName}
                      onChange={(value) =>
                        setFormData({
                          ...formData,
                          employeeName: value,
                        })
                      }
                      showSearch
                      placeholder="Select Employee"
                      optionFilterProp="label"
                      className="wow"
                      // onSearch={onSearch}
                      options={[
                        {
                          value: "Jack",
                          label: "Jack",
                        },
                        {
                          value: "Lucy",
                          label: "Lucy",
                        },
                        {
                          value: "Tom",
                          label: "Tom",
                        },
                        {
                          value: "Root",
                          label: "Root",
                        },
                        {
                          value: "William",
                          label: "William",
                        },
                        {
                          value: "Khans",
                          label: "Khans",
                        },
                      ]}
                    />
                    {/* <input
                      type="text"
                      name="employeeName"
                      value={formData.employeeName}
                      onChange={handleChange}
                      placeholder="Employee Name"
                      className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                    /> */}
                  </div>
                </div>

                {/* Reason Field */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Reason
                  </label>
                  <textarea
                    name="reason"
                    value={formData.reason}
                    onChange={handleChange}
                    rows="4"
                    className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Reason for Coaching and/or Verbal Warning"
                  ></textarea>
                </div>

                <div className="mb-4">
                  <h3 className="text-md font-semibold mb-2">
                    Coaching{"-"}
                    <span className="font-normal text-md">
                      I have reviewed the policies and procedures with the
                      employee and they understand and agree to follow said
                      policies and procedures.
                    </span>
                  </h3>
                  <h3 className="text-md font-semibold mb-2">
                    Verbal Warning{"-"}
                    <span className="font-normal text-md">
                      Employee has had previous coaching sessions and is still
                      failing to follow the policies and procedures.
                    </span>
                  </h3>
                </div>

                {/* Type of Disciplinary Action */}
                <div className="mb-4 w-[40%]">
                  <label className="block text-sm font-medium mb-2">
                    Type of Disciplinary Action
                  </label>
                  <select
                    name="disciplinaryAction"
                    value={formData.disciplinaryAction}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select an Action</option>
                    <option value="warning">Warning</option>
                    <option value="suspension">Suspension</option>
                    <option value="termination">Termination</option>
                  </select>
                </div>

                {/* Nature of Deficiency */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Nature of Deficiency
                  </label>
                  <textarea
                    name="deficiency"
                    value={formData.deficiency}
                    onChange={handleChange}
                    rows="4"
                    className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Specify what, where, when, and how employee's behavior and performance was deficient"
                  ></textarea>
                </div>

                {/* Attachments */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Attachments (if available)
                  </label>
                  <input
                    type="file"
                    className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                {/* Agreement Checkbox */}
                <div className="mb-4 flex items-center mt-2">
                  <label className="text-md font-medium">
                    I, undersigned, agree with the followings.
                  </label>
                </div>
                <div className="mb-4 flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2 rounded border-gray-300 focus:ring-blue-500"
                  />
                  <label className="text-sm">
                    I have, on this date, spoken to the employee stating that
                    the above conduct is not acceptable, and if repeated, will
                    become the basis for formal disciplinary action.
                  </label>
                </div>
                <div className="mb-6 flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2 rounded border-gray-300 focus:ring-blue-500"
                  />
                  <label className="text-sm">
                    I have also informed said employee that the imposition of
                    disciplinary action, up to and including termination, is not
                    pre-conditioned upon receipt of verbal or written notice of
                    unacceptable conduct.
                  </label>
                </div>

                {/* Signature Pad */}
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Signature{" "}
                    <span className="text-red-600 font-medium">
                      ( Tom Jones , Manager )
                    </span>
                  </label>
                  <div className="border border-gray-300 rounded-lg">
                    <SignatureCanvas
                      ref={signaturePad}
                      onEnd={handleEnd}
                      canvasProps={{
                        width: 400,
                        height: 130,
                        className: "signatureCanvas",
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={clearSignature}
                    className="mt-2 text-sm text-blue-500"
                  >
                    Clear Signature
                  </button>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Signature{" "}
                    <span className="text-red-600 font-medium">
                      ( R. C. Chung - Director of Human Resources )
                    </span>
                  </label>
                  <div className="border border-gray-300 rounded-lg">
                    <SignatureCanvas
                      ref={directorSignaturePad}
                      onEnd={handleEndDirector}
                      canvasProps={{
                        width: 400,
                        height: 130,
                        className: "signatureCanvas",
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={clearDirectorSignature}
                    className="mt-2 text-sm text-blue-500"
                  >
                    Clear Signature
                  </button>
                </div>
                {/* Download PDF Button */}
                {/* <PDFDownloadLink
                  document={<MyDocument formData={formData} />}
                  fileName="coaching_warning_form.pdf"
                >
                  {({ loading }) => (
                    <button
                      type="button"
                      disabled={loading}
                      className="bg-blue-500 text-white p-3 rounded-lg w-full mt-6"
                    >
                      {loading ? "Preparing PDF..." : "Download PDF"}
                    </button>
                  )}
                </PDFDownloadLink> */}
              </form>
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="flex items-center justify-center p-4 gap-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
          {/* <PDFDownloadLink
            document={<MyDocument formData={formData} />}
            fileName="coaching_warning_form.pdf"
          >
            {({ loading }) => (
              <button
                type="button"
                disabled={loading}
                className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 text-center w-[12rem] "
              >
                View PDF
              </button>
            )}
          </PDFDownloadLink> */}
          <button
            onClick={handleOpenPDF}
            className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 text-center w-[12rem] "
          >
            View PDF
          </button>
          <button
            type="button"
            className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 text-center w-[12rem]"
          >
            Send
          </button>
          {/* <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={handleOpenPDF}
              className="bg-blue-500 text-white p-3 rounded-lg"
            >
              Open PDF in New Tab
            </button>
          </div> */}
        </div>
      </div>
      {showError && <ErrorModal setShow={setShowError} err={err} />}
    </div>
  );
};

export default Disciplineform;
