import axios from "axios";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const EmployeeInfo = ({
  setShow,
  setShowErrorModal,
  setErr,
  setRefetch,
  refetch,
}) => {
  const [role, setRole] = useState("");
  const check = true;
  const token = useSelector((state) => state.token);
  const [imageUrl, setImageUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("tier");
  const router = useNavigate();
  const { Option } = Select;
  const [priceSet, setPriceSet] = useState("");
  const [levelName, setLevelName] = useState("");
  const [type, setType] = useState("");
  const [specificGroup, setSpecificGroup] = useState("");
  const [minimum, setMinimum] = useState(null);
  const [maximum, setMaximum] = useState(null);
  const [price, setPrice] = useState(null);
  const ProductId = useSelector((state) => state.ProductId);
  const [programs, setPrograms] = useState([]);
  const [buyerProgram, setBuyerProgram] = useState(null); // Buyer-specific program

  const nextHandler = () => {
    setShow(false);
    var body;
    body =
      selectedOption === "tier"
        ? {
            priceSet: "tier-price",
            levelName: levelName,
            type: type,
            minimum: minimum,
            maximum: maximum,
            price: price,
          }
        : {
            priceSet: "specific-price",
            // specificGroup: specificGroup,
            specificGroup: buyerProgram,
            type: "specific",
            price: price,
          };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-product/${ProductId}/add-advance-price`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setShow(false);
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPrograms(data?.data);
      })
      .catch(() => {});
  }, []);

  const options = programs?.map((item) => ({
    value: item?.name,
    label: item?.name,
    imageUrl: item?.icon,
  }));

  const formatProgramNames = (data) => {
    if (data === "plwd") return "PLWD";
    else if (data === "pa-medical") return "PA Medical";
    else if (data === "pa-economical") return "PA Economical";
    else
      return data
        .replace(/-/g, " ") // Replace hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex items-center">
        <img
          src={option?.imageUrl}
          alt={option?.label}
          style={{ width: 24, marginRight: 8 }}
        />
        <span>
          {formatProgramNames(
            option?.label === "plwd"
              ? "PLWD"
              : option?.label === "pa-medical"
              ? "PA Medical"
              : option?.label === "pa-economical"
              ? "PA Economical"
              : option?.label
          )}
        </span>
      </div>
    </Option>
  );
  return (
    <>
      <div
        className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-30 `}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between p-4 md:p-4 border-b rounded-t ">
              <h3 className="text-lg font-semibold text-gray-900 ">
                Add New Price
              </h3>
              <button
                onClick={() => {
                  setShow(false);
                }}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                data-modal-toggle="select-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="flex justify-center">
              <div className="w-[90%] mt-3">
                <div className="w-full gap-3 bg-[#F7F7F7] rounded-md py-2 px-3">
                  <div className="flex gap-4  items-center">
                    <div className="mb-2 flex items-center">
                      <input
                        type="radio"
                        id="gidOption"
                        name="contactMethod"
                        value="tier"
                        className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                        checked={selectedOption === "tier"}
                        onChange={(e) => {
                          setSelectedOption(e.target.value);
                        }}
                      />
                      <label
                        htmlFor="gidOption"
                        className="cursor-pointer text-sm inline-flex items-center ml-2"
                      >
                        Tier Price
                      </label>
                    </div>
                    <div className="mb-2 flex items-center">
                      <input
                        type="radio"
                        id="phoneOption"
                        name="contactMethod"
                        value="specific"
                        className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                        checked={selectedOption === "specific"}
                        onChange={(e) => {
                          setSelectedOption(e.target.value);
                        }}
                      />
                      <label
                        htmlFor="phoneOption"
                        className="cursor-pointer text-sm inline-flex items-center ml-2"
                      >
                        Specific Price
                      </label>
                    </div>
                  </div>
                </div>
                <div className={`${selectedOption === "tier" ? "" : "hidden"}`}>
                  <h2 className="text-gray-600 text-[0.9rem] font-semibold">
                    {selectedOption === "tier"
                      ? "Tier Name"
                      : "Specific Pricing Name"}
                  </h2>

                  <input
                    type="text"
                    placeholder={
                      selectedOption === "tier"
                        ? "Tier Name"
                        : "Specific Pricing Name"
                    }
                    value={
                      selectedOption === "tier" ? levelName : specificGroup
                    }
                    onChange={(e) => {
                      if (selectedOption === "tier") {
                        setLevelName(e.target.value);
                      } else {
                        setSpecificGroup(e.target.value);
                      }
                    }}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                  />
                </div>

                <div
                  className={`${selectedOption !== "tier" ? "mt-2" : "hidden"}`}
                >
                  <h2 className="text-gray-600 text-[0.9rem] font-semibold">
                    Program
                  </h2>

                  <Select
                    placeholder="Select Program"
                    // className="wow w-[100%] mb-4"
                    className="w-[100%] woww"
                    value={buyerProgram}
                    renderOption={renderOption}
                    onChange={
                      (value) => {
                        setBuyerProgram(value);
                      }
                      // console.log(value)
                    }
                  >
                    {options?.map(renderOption)}
                  </Select>
                </div>
                <div className="mt-2">
                  <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                    Price
                  </h2>

                  <input
                    type="text"
                    placeholder="Price"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 "
                  />
                </div>

                <div
                  className={`${selectedOption === "tier" ? "mt-2" : "hidden"}`}
                >
                  <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                    Type:
                  </h2>

                  <Select
                    placeholder="Select Type"
                    optionFilterProp="children"
                    className="wow"
                    //   value={role}
                    onChange={(val) => setType(val)}
                    size="middle"
                    options={[
                      { value: "weight", label: "Weight" },
                      { value: "quantity", label: "Quantity" },
                    ]}
                  />
                </div>

                <div
                  className={`${
                    selectedOption === "tier"
                      ? "flex items-center justify-between gap-2 mt-2"
                      : "hidden"
                  }`}
                >
                  <div>
                    <label className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Min Unit
                    </label>
                    <input
                      type="text"
                      placeholder="Min Unit"
                      value={minimum}
                      onChange={(e) => setMinimum(e.target.value)}
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                  <div>
                    <label className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Max Unit
                    </label>
                    <input
                      type="text"
                      placeholder="Max Unit"
                      value={maximum}
                      onChange={(e) => setMaximum(e.target.value)}
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    />
                  </div>
                </div>

                <div className="flex justify-center pb-6 mt-4">
                  <button
                    onClick={() => {
                      setShow(false);
                      router("/Inventory");
                    }}
                    className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[45%]"
                  >
                    Skip
                  </button>
                  <button
                    onClick={() => {
                      nextHandler();
                      // setShow(false);
                      // setShowSuccessModal(true);
                    }}
                    // onClick={nextHandler}
                    className="ml-5 cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[45%]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeInfo;
