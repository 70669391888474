import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import activeDisc from "../../../../imgs/logo12.png";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { TextBox } from "@react-pdf-viewer/core";

const DisciplineForm = () => {
  const [formData, setFormData] = useState({
    date: "",
    supervisorFirstName: "",
    supervisorLastName: "",
    supervisorEmail: "",
    employeeName: "",
    reason: "",
    disciplinaryAction: "",
    deficiency: "",
    signature: "",
  });

  const signaturePad = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearSignature = () => {
    signaturePad.current.clear();
    setFormData({
      ...formData,
      signature: "",
    });
  };

  // Update signature in form data when user signs
  const handleEnd = () => {
    const signatureData = signaturePad.current.toDataURL();
    setFormData({
      ...formData,
      signature: signatureData,
    });
  };

  // Define styles
  const styles = StyleSheet.create({
    page: { padding: 30, display: "grid" },
    section: {
      marginBottom: 10,
    },
    title: { fontSize: 24, textAlign: "center", marginBottom: 25 },
    label: {
      fontWeight: "extrabold",
      fontSize: 12,
      marginBottom: 6,
      color: "#101010",
    },
    input: {
      fontSize: 10.5,
      padding: 8,
      marginBottom: 10,
      border: "1px solid #ddd",
      borderRadius: 5,
      color: "#484848",
    },
    textarea: {
      fontSize: 10.5,
      padding: 8,
      border: "1px solid #ddd",
      borderRadius: 5,
      color: "#484848",
    },
    signatureContainer: { marginBottom: 20 },
    signatureCanvas: { border: "1px solid #ddd" },
    button: {
      padding: 10,
      backgroundColor: "#256091",
      color: "#fff",
      borderRadius: 5,
      textAlign: "center",
    },
    logo: {
      position: "absolute",
      top: 10,
      left: 40,
      width: 60,
      height: 60,
    },
  });

  // Create PDF Document Component
  const MyDocument = ({ formData }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Add Logo */}
        <Image src={activeDisc} style={styles.logo} />

        <Text style={styles.title}>Coaching and/or Verbal Warning</Text>
        <View>
          <TextBox>Coaching and/or Verbal Warning</TextBox>
        </View>

        <View style={styles.section}>
          <Text style={styles.label}>Date</Text>
          <Text style={styles.input}>{formData.date}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Field Supervisor Name</Text>
          <Text style={styles.input}>
            First Name: {formData.supervisorFirstName}
          </Text>
          <Text style={styles.input}>
            Last Name: {formData.supervisorLastName}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Supervisor Email Address</Text>
          <Text style={styles.input}>{formData.supervisorEmail}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Employee Name</Text>
          <Text style={styles.input}>{formData.employeeName}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Reason</Text>
          <Text style={styles.textarea}>{formData.reason}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Type of Disciplinary Action</Text>
          <Text style={styles.input}>{formData.disciplinaryAction}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.label}>Nature of Deficiency</Text>
          <Text style={styles.textarea}>{formData.deficiency}</Text>
        </View>
        {/* Signatures */}
        <View style={styles.signatureContainer}>
          <Text style={styles.label}>Authorized Signature</Text>
          <View style={styles.signatureCanvas}>
            {formData.signature ? (
              <Image
                style={{ width: 250, height: 100 }}
                src={formData.signature}
              />
            ) : (
              <Text>(Signature Here)</Text>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-3xl">
        <h2 className="text-2xl font-bold text-center mb-6">
          Coaching and/or Verbal Warning
        </h2>
        <form>
          {/* Date Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Date</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Field Supervisor Name */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <input
                type="text"
                name="supervisorFirstName"
                value={formData.supervisorFirstName}
                onChange={handleChange}
                placeholder="First Name"
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <input
                type="text"
                name="supervisorLastName"
                value={formData.supervisorLastName}
                onChange={handleChange}
                placeholder="Last Name"
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          {/* Supervisor Email Address */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium mb-2">
                Supervisor Email Address
              </label>
              <input
                type="email"
                name="supervisorEmail"
                value={formData.supervisorEmail}
                onChange={handleChange}
                placeholder="Email Address"
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Employee Name
              </label>
              <input
                type="text"
                name="employeeName"
                value={formData.employeeName}
                onChange={handleChange}
                placeholder="Employee Name"
                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          {/* Reason Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Reason</label>
            <textarea
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              rows="4"
              className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Reason for Coaching and/or Verbal Warning"
            ></textarea>
          </div>

          {/* Type of Disciplinary Action */}
          <div className="mb-4 w-[40%]">
            <label className="block text-sm font-medium mb-2">
              Type of Disciplinary Action
            </label>
            <select
              name="disciplinaryAction"
              value={formData.disciplinaryAction}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Select an Action</option>
              <option value="warning">Warning</option>
              <option value="suspension">Suspension</option>
              <option value="termination">Termination</option>
            </select>
          </div>

          {/* Nature of Deficiency */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Nature of Deficiency
            </label>
            <textarea
              name="deficiency"
              value={formData.deficiency}
              onChange={handleChange}
              rows="4"
              className="w-full border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Specify what, where, when, and how employee's behavior and performance was deficient"
            ></textarea>
          </div>

          {/* Signature Pad */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Signature</label>
            <div className="border border-gray-300 rounded-lg">
              <SignatureCanvas
                ref={signaturePad}
                onEnd={handleEnd}
                canvasProps={{
                  width: 400,
                  height: 150,
                  className: "signatureCanvas",
                }}
              />
            </div>
            <button
              type="button"
              onClick={clearSignature}
              className="mt-2 text-sm text-blue-500"
            >
              Clear Signature
            </button>
          </div>

          {/* Download PDF Button */}
          <PDFDownloadLink
            document={<MyDocument formData={formData} />}
            fileName="coaching_warning_form.pdf"
          >
            {({ loading }) => (
              <button
                type="button"
                disabled={loading}
                className="bg-blue-500 text-white p-3 rounded-lg w-full mt-6"
              >
                {loading ? "Preparing PDF..." : "Download PDF"}
              </button>
            )}
          </PDFDownloadLink>
        </form>
      </div>
    </div>
  );
};

export default DisciplineForm;
