import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import SuccessModal from "../../../modals/successModal";
import axios from "axios";
import { Button, DatePicker, Pagination, Select, Switch } from "antd";
import { UploadOutlined, DeleteFilled } from "@ant-design/icons";
import Nationalties from "../../../../constants/country";
import dayjs from "dayjs";

let file;
let rr;

const SelectProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [data, setData] = useState([]);
  const [type, setType] = useState(null);
  const [afterApi, setAfterApi] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [addChargesModal, setAddChargesModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [search, setSearch] = useState("");

  const taxData = useSelector((state) => state.Data);
  console.log(taxData);
  const user = useSelector((state) => state.user);
  const [taxable, setTaxable] = useState(false);
  const [display, setDisplay] = useState("");
  const [businessType, setBusinessType] = useState(null);
  const [docType, setDocType] = useState(null);
  const dataUser = useSelector((state) => state.dataUser);
  const profileData = useSelector((state) => state?.profileData);
  const [doc, setDoc] = useState(null);
  const [num, setNum] = useState("");
  const [country, setCountry] = useState(null);
  const [issue, setIssue] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [display1, setDisplay1] = useState("");
  const [urlFront, setUrlFront] = useState("");
  const [urlBack, setUrlBack] = useState("");

  const [option, setOption] = useState([]);

  const pageHandler = (e) => {
    setPage(e);
  };

  rr = Nationalties?.map((item) => {
    return {
      lable: `${item}`,
      value: `${item}`,
    };
  });

  useEffect(() => {
    if (taxData && user === "vendor-owner") {
      console.log(taxData, "uooj");
      setTaxable(true);
      setBusinessType(taxData?.businessType);
      setDocType(taxData?.docType);
      setCountry(taxData?.countryIssued);
      setIssue(taxData?.issuedDate);
      setExpiry(taxData?.expiry);
      setNum(taxData?.docNo);
      setUrlFront(taxData?.docFrontImg);
      setUrlBack(taxData?.docBackImg);
      // Check if frontUrl and backUrl are available
      const urlsToFetch = [taxData?.docFrontImg, taxData?.docBackImg]?.filter(
        Boolean
      );

      console.log(urlsToFetch);
      const api =
        user === "vendor-owner"
          ? "v1/file/vendor/get-image-url?fileType=document"
          : "v1/file/get-image-url?fileType=document";

      if (urlsToFetch?.length > 0) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/${api}`,
            {
              fileNames: urlsToFetch,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            const fetchedUrls = response?.data;

            // Assuming the response order matches the input order
            if (fetchedUrls?.length > 0) {
              setDisplay(fetchedUrls?.[0]?.url || ""); // Set front URL
            }
            if (fetchedUrls?.length > 1) {
              setDisplay1(fetchedUrls?.[1]?.url || ""); // Set back URL
            }
          })
          .catch(() => {
            console.error("Error fetching image URLs");
          });
      }
    }
  }, []);
  //   //Get Listing
  //   useEffect(() => {
  //     dispatch({ type: "EDIT", num: false });
  //     dispatch({ type: "DATA", num: null });

  //     setAfterApi(false);
  //     const delay = 1000;
  //     const timeoutId = setTimeout(() => {
  //       if (cancelTokenSource) {
  //         cancelTokenSource.cancel("Operation canceled by the user.");
  //       }

  //       const newCancelTokenSource = axios.CancelToken.source();
  //       setCancelTokenSource(newCancelTokenSource);

  //       const params = {
  //         limit: 6,
  //         skip: 0,
  //         page: page,
  //         sortBy: "createdAt:desc",
  //         // ...(search && { search }),
  //       };

  //       fetchAllAccess(params, newCancelTokenSource);
  //     }, delay);

  //     return () => {
  //       clearTimeout(timeoutId);

  //       if (cancelTokenSource) {
  //         cancelTokenSource.cancel("Operation canceled by the user.");
  //       }
  //     };
  //   }, [page, refetch]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-buyer-tax-doc/own?buyerType=mhsss-global`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params,
          // cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        const data = res?.data;
        if (data) {
          setTaxable(true);
          setBusinessType(data?.businessType);
          setDocType(data?.docType);
          setCountry(data?.countryIssued);
          setIssue(data?.issuedDate);
          setExpiry(data?.expiry);
          setNum(data?.docNo);
          setUrlFront(data?.docFrontImg);
          setUrlBack(data?.docBackImg);

          // Check if frontUrl and backUrl are available
          const urlsToFetch = [data?.docFrontImg, data?.docBackImg]?.filter(
            Boolean
          );

          const api =
            user === "vendor-owner"
              ? "v1/file/vendor/get-image-url?fileType=document"
              : "v1/file/get-image-url?fileType=document";

          if (urlsToFetch?.length > 0) {
            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}/${api}`,
                {
                  fileNames: urlsToFetch,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                const fetchedUrls = response?.data;

                // Assuming the response order matches the input order
                if (fetchedUrls.length > 0) {
                  setDisplay(fetchedUrls[0]?.url || ""); // Set front URL
                }
                if (fetchedUrls.length > 1) {
                  setDisplay1(fetchedUrls[1]?.url || ""); // Set back URL
                }
              })
              .catch(() => {
                console.error("Error fetching image URLs");
              });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (user !== "vendor-owner") {
      fetchAllAccess();
      return;
    }
  }, []);

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      if (event) {
        let wow;
        if (event.$M <= 9) {
          if (event.$D <= 9) {
            wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
          } else {
            wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
          }
        } else {
          if (event.$D <= 9) {
            wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
          } else {
            wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
          }
        }
        setState(wow);
      }
    } else {
      setState(event.target.value);
    }
  };
  const imageHandlerr = async (setDis, setUrl, e) => {
    file = e.target.files[0];
    const base641 = await convertToBase64(file);

    console.log("base", base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    const api =
      user === "vendor-owner"
        ? "v1/file/vendor/get-image-url?fileType=document"
        : "v1/file/get-image-url?fileType=document";
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/${api}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        setUrl(data?.data?.url);
        setDis(base641);
        e.target.value = null;
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const AddHandler = () => {
    let body = {
      businessType,
      docType: "vat",
      docFrontImg: urlFront,
      docBackImg: urlBack,
      docNo: num,
      expiry,
      issuedDate: issue,
      countryIssued: country,
      buyerType: "mhsss-global", // Allowed: ['mhsss-global', 'user']
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/inv-buyer-tax-doc`, body, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then(() => {
        setErr("Tax Setting Added Successfully");
        setShow(true);
      })
      .catch((err) => {
        if (err.response.data.message === '"expiryDate" must be a valid date') {
          setErr("Please verify if expiration date is available on card");
        } else if (
          err.response.data.message === '"imageUrl" is not allowed to be empty'
        ) {
          setErr("Please Upload required images of the document.");
        } else {
          setErr(err.response.data.message);
        }

        setShowErr(true);
      });
  };
  return (
    <div>
      <div className="flex justify-center mt-3">
        <div className={`bg-[#fafaf9] shadow-lg rounded-sm  p-6  w-full mx-3`}>
          <div className="px-10 py-3">
            <div className="flex items-end justify-between rounded-md mt-2 mb-2 w-[30%] ">
              <div>
                <h1 className="text-gray-700 text-[1.1rem] font-semibold mb-2">
                  Taxable
                </h1>
              </div>

              <Switch
                // checkedChildren="Whitelist"
                // unCheckedChildren="Blacklist"
                // className="absolute top-4 right-4"
                size="8px"
                style={{
                  fontSize: "2px",
                  backgroundColor: taxable === true ? "#1BA397" : "#c3c6c9",
                }}
                checked={taxable === true}
                onClick={() => {
                  setTaxable(!taxable);
                }}
              />
            </div>
            <div
              className={`${taxable ? "" : "hidden"} ${
                user === "vendor-owner" ? "pointer-events-none" : ""
              }`}
            >
              <h4
                className={`${
                  user !== "vendor-owner"
                    ? "font-semibold text-gray-700 mb-6 mt-6 text-[0.95rem]"
                    : "hidden"
                }`}
              >
                Kindly provide details for VAT REGISTERED CERITIFICATE (VAT)
              </h4>
              <div className="w-full grid grid-cols-2 gap-x-4 mt-4">
                <div className="flex flex-col">
                  <label className="text-gray-700 text-[0.96rem] font-semibold mb-2">
                    Business Type <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                    placeholder="Business Type"
                    value={businessType}
                    onChange={(e) => setBusinessType(e.target.value)}
                  />

                  {/* <Select
                  placeholder="Select Type"
                  optionFilterProp="children"
                  className="wow"
                  style={{ marginBottom: "1rem" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="middle"
                  //   onChange={(value) => setPricingType(value)}
                  //   value={pricingType}
                  options={[
                    {
                      value: "per-item",
                      label: "Per Item",
                    },
                    {
                      value: "per-weight",
                      label: "Per Weight",
                    },
                  ]}
                /> */}
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-700 text-[0.96rem] font-semibold mb-2">
                    Country Issued <span className="text-red-600">*</span>
                  </label>
                  <Select
                    placeholder="Select Type"
                    optionFilterProp="children"
                    className="wow"
                    style={{ marginBottom: "1rem" }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size="middle"
                    onChange={(value) => setCountry(value)}
                    options={rr}
                    value={country}
                  />
                </div>

                {/* <div className="flex flex-col">
                  <label className="text-gray-700 text-[0.96rem] font-semibold mb-2">
                    Document Type <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setDocType(e.target.value);
                    }}
                    value={docType}
                    placeholder={`Document Type`}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                 
                </div> */}
                <div className="flex flex-col">
                  <label className="text-gray-700 text-[0.96rem] font-semibold mb-2">
                    Document Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setNum(e.target.value);
                    }}
                    value={num}
                    placeholder={`Document Number`}
                    className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-700 text-[0.96rem] font-semibold mb-2">
                    Issued Date <span className="text-red-600">*</span>
                  </label>
                  <DatePicker
                    placeholder="Issued Date"
                    style={{ marginBottom: "1.2rem" }}
                    //   disabledDate={disabledDate1}
                    className="date-class  h-[2.7rem]"
                    onChange={handleChange(setIssue, "tt")}
                    value={issue ? dayjs(issue) : null}
                  />
                </div>

                <div className="flex flex-col">
                  <label className="text-gray-700 text-[0.96rem] font-semibold mb-2">
                    Expiry Date <span className="text-red-600">*</span>
                  </label>
                  <DatePicker
                    placeholder="Expiry Date"
                    style={{ marginBottom: "1.2rem" }}
                    //   disabledDate={disabledDate1}
                    className="date-class  h-[2.7rem]"
                    onChange={handleChange(setExpiry, "tt")}
                    value={expiry ? dayjs(expiry) : null}
                  />
                </div>
              </div>

              {/* Documents */}
              <div className="flex flex-col w-[55%] ">
                <label className="text-gray-700 text-[0.96rem] font-semibold mb-2">
                  Document <span className="text-red-600">*</span>
                </label>
                <div className="w-full flex gap-3">
                  <div
                    className={`flex items-center justify-center rounded-lg text-center sm:w-[50%] h-[11rem] relative`}
                  >
                    {display ? (
                      <>
                        <img
                          src={display}
                          alt="Uploaded"
                          className="w-full h-full"
                        />
                        <button
                          className="absolute bottom-1 right-1"
                          onClick={() => {
                            setUrlFront("");
                            setDisplay("");
                          }}
                        >
                          <DeleteFilled
                            className={`${
                              user === "vendor-owner"
                                ? "hidden"
                                : "text-2xl text-primary cursor-pointer"
                            }`}
                          />
                        </button>
                      </>
                    ) : (
                      <div className="w-full h-full flex flex-col gap-1 flex bg-gray-100 p-4 items-center justify-center">
                        <div className="flex items-center w-full justify-center flex-col">
                          <UploadOutlined className="text-4xl text-primary cursor-pointer " />
                          <label
                            htmlFor="file-upload"
                            className="text-5xl text-primary w-full cursor-pointer flex flex-col gap-2 items-center justify-center"
                          >
                            <input
                              id="file-upload"
                              type="file"
                              className="hidden"
                              accept="image/*"
                              onChange={(e) =>
                                imageHandlerr(setDisplay, setUrlFront, e)
                              }
                            />
                            <div className="mt-4 w-[80%] text-sm p-2 cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg">
                              Upload Front
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className={`flex items-center justify-center rounded-lg text-center sm:w-[50%] h-[11rem] relative`}
                  >
                    {display1 ? (
                      <>
                        <img
                          src={display1}
                          alt="Uploaded"
                          className="w-full h-full"
                        />
                        <button
                          className="absolute bottom-1 right-1"
                          onClick={() => {
                            setUrlBack("");
                            setDisplay1("");
                          }}
                        >
                          <DeleteFilled
                            className={`${
                              user === "vendor-owner"
                                ? "hidden"
                                : "text-2xl text-primary cursor-pointer"
                            }`}
                          />
                        </button>
                      </>
                    ) : (
                      <div className="w-full h-full flex flex-col gap-1 flex bg-gray-100 p-4 items-center justify-center">
                        <div className="flex items-center w-full justify-center flex-col">
                          <UploadOutlined className="text-4xl text-primary cursor-pointer " />
                          <label
                            htmlFor="file-upload"
                            className="text-5xl text-primary w-full cursor-pointer flex flex-col gap-2 items-center justify-center"
                          >
                            <input
                              id="file-upload"
                              type="file"
                              className="hidden"
                              accept="image/*"
                              onChange={(e) =>
                                imageHandlerr(setDisplay1, setUrlBack, e)
                              }
                            />
                            <div className="mt-4 w-[80%] text-sm p-2 cursor-pointer text-center bg-white border-primary text-primary border-2  px-4 rounded-lg">
                              Upload Back
                            </div>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`${
                  user !== "vendor-owner"
                    ? "flex items-center justify-center mt-6 mb-6"
                    : "hidden"
                }`}
              >
                <button
                  onClick={() => {
                    AddHandler();
                  }}
                  className="cursor-pointer ml-4 rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
                >
                  Save
                </button>
              </div>
            </div>
            <div
              className={`${
                user === "vendor-owner"
                  ? "flex items-center justify-center mt-12 mb-6"
                  : "hidden"
              }`}
            >
              <button
                onClick={() => {
                  // AddHandler();
                }}
                className="cursor-pointer ml-4 rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[12rem]"
              >
                Reject
              </button>
              <button
                onClick={() => {
                  // AddHandler();
                }}
                className="cursor-pointer ml-4 rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>

      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {show && (
        <SuccessModal
          show={show}
          setShow={setShow}
          message={err}
          //   route="/scheduleVerification/selectedEmployees"
        />
      )}
    </div>
  );
};

export default SelectProgram;
