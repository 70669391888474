import back5 from "../../../imgs/back5.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import EventDetail from "./eventDetails";

const EventDetails = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const user = useSelector((state) => state.user);
  return (
    <div className="flex items-center justify-center pt-[2rem] flex-col">
      <div
        className={`${
          user === "merchant-owner" ? "sm:w-[90%] w-full" : "sm:w-[80%] w-full"
        }  flex flex-col items-center justify-center`}
      >
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                if (user === "merchant-owner") {
                  if (dash === "/Merchant/SubEventDetail") {
                    router("/MerchantDashBoard/Subevents");
                  } else {
                    router("/MerchantDashBoard/events");
                  }
                  return;
                }
                else if (
                  dash === "/EventDetails" ||
                  dash === "/Merchant/EventDetail"
                ) {
                  if (user === "merchant-owner") {
                    router("/MerchantDashBoard/events");
                  } else if (user === "pensioner")
                    router("/PensionerDashBoard/events");
                  else router("/EmployeeDashBoard/events");
                } else if (dash === "/SubEventDetails") {
                  if (user === "pensioner")
                    router("/PensionerDashBoard/Subevents");
                  else router("/EmployeeDashBoard/Subevents");
                }
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[0.5rem] text-[1.4rem]">
                {dash === "/EventDetails" || dash === "/Merchant/EventDetail"
                  ? "Event"
                  : "Sub Event"}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          user === "merchant-owner" ? "sm:w-[90%] w-full" : " sm:w-[80%] w-full"
        }  p-2`}
      >
        <EventDetail />
      </div>
    </div>
  );
};

export default EventDetails;
