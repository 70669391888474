import React from "react";
import { Form, Input, Row, Col, Upload, Select, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const EditEmployees = ({ initialValues = {}, onSave }) => {
  const [form] = Form.useForm();
  const router = useNavigate();

  const handleFinish = (values) => {
    console.log("Form values:", values);
    router("/AttendanceEmployees");

    onSave && onSave(values);
  };
  const onCancel = (params) => {
    router("/AttendanceEmployees");
  };

  return (
    <div className="p-6 bg-white">
      <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleFinish}>
        <Row gutter={16}>
          {/* Employee Name */}
          <Col span={12}>
            <Form.Item
              label="Employee Name"
              name="name"
              rules={[{ required: true, message: "Please enter the employee name" }]}>
              <Input placeholder="Enter employee name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Employee ID Number"
              name="employeeIdNumber"
              rules={[{ required: true, message: "Please Enter ID" }]}>
              <Input placeholder="Enter ID" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Mobile Phone"
                  name="mobilePhone"
                  rules={[{ required: true, message: "Please enter mobile Phone" }]}>
                  <Input placeholder="Enter mobile Phone" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Home Phone"
                  name="homePhone"
                  rules={[{ required: true, message: "Please enter home Phone" }]}>
                  <Input placeholder="Enter home Phone" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={16}>
              {/* Image Upload */}
              <Col span={8}>
                <Form.Item
                  label="Employee GID/Passport"
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}>
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onClick={(e) => e.preventDefault()} // Prevents form submission
                  >
                    <Button transparent className="border-dotted" icon={<UploadOutlined />}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col span={16}>
                <Col span={24}>
                  <Form.Item
                    label="ID Number"
                    name="IdNumber"
                    rules={[{ required: true, message: "Please Enter ID" }]}>
                    <Input placeholder="Enter ID" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Expired"
                    name="expired"
                    rules={[{ required: true, message: "Expired Enter" }]}>
                    <DatePicker placeholder="Select date" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ type: "email", message: "Please enter a valid email" }]}>
                  <Input placeholder="Enter email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date of Birth"
                  name="dateOfBirth"
                  rules={[{ required: true, message: "Date of Birth Enter" }]}>
                  <DatePicker placeholder="Select date" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Country Issued"
                  name="countryIssued"
                  rules={[{ required: true, message: "Please enter the country Issued" }]}>
                  <Input placeholder="Enter country Issued" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country of Birth"
                  name="countryOfBirth"
                  rules={[{ required: true, message: "Please enter the country Of Birth" }]}>
                  <Input placeholder="Enter country Of Birth" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Marital Status */}
          <Col span={12}>
            <Form.Item
              label="Marital Status"
              name="maritalStatus"
              rules={[{ required: true, message: "Please select marital status" }]}>
              <Select placeholder="Select marital status">
                <Select.Option value="Single">Single</Select.Option>
                <Select.Option value="Married">Married</Select.Option>
                <Select.Option value="Divorced">Divorced</Select.Option>
                <Select.Option value="Widowed">Widowed</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Gender */}
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}>
              <Select placeholder="Select gender">
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Employment Status"
                  name="employmentStatus"
                  rules={[{ required: true, message: "Please select employment status" }]}>
                  <Select
                    placeholder="Select employment status"
                    options={[
                      { label: "Active", value: "active" },
                      { label: "Deactive", value: "deactive" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Citizen"
                  name="citizen"
                  rules={[{ required: true, message: "Please enter the citizen" }]}>
                  <Input placeholder="Enter citizen" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Permanent Resident"
                  name="permanentResident"
                  rules={[{ required: true, message: "Please enter the permanent Resident" }]}>
                  <Input placeholder="Enter permanent Resident" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Diaspora/Expat"
                  name="permanentResident"
                  rules={[{ required: true, message: "Please enter the diaspora/Expat" }]}>
                  <Input placeholder="Enter diaspora/Expat" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Work Permit"
                  name="workPermit"
                  rules={[{ required: true, message: "Please specify if work permit holder" }]}>
                  <Select
                    placeholder="Select work permit status"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                  />
                </Form.Item>
              </Col>

              {/* Visa */}
              <Col span={12}>
                <Form.Item
                  label="Visa"
                  name="visa"
                  rules={[{ required: true, message: "Please specify if visa holder" }]}>
                  <Select
                    placeholder="Select visa status"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="TIN"
                  name="tin"
                  rules={[{ required: true, message: "Please enter the tIN" }]}>
                  <Input placeholder="Enter tIN" />
                </Form.Item>
              </Col>{" "}
              <Col span={12}>
                <Form.Item
                  label="NIS"
                  name="nis"
                  rules={[{ required: true, message: "Please enter the nIS" }]}>
                  <Input placeholder="Enter nIS" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Position */}
          <Col span={12}>
            <Form.Item
              label="Position"
              name="position"
              rules={[{ required: true, message: "Please enter the position" }]}>
              <Input placeholder="Enter position" />
            </Form.Item>
          </Col>

          {/* Department */}
          <Col span={12}>
            <Form.Item
              label="Departments"
              name="departments"
              rules={[{ required: true, message: "Please select department" }]}>
              <Select
                placeholder="Select departmet"
                options={[
                  { label: "Matron’s Office Clerks", value: "Matron’s Office Clerks" },
                  { label: "Nursing Services", value: "Nursing Services" },
                  { label: "Pediatrics Medical", value: "Pediatrics Medical" },
                  { label: "Female Medical", value: "Female Medical" },
                  { label: "ACDC", value: "ACDC" },
                  { label: "Maternity", value: "Maternity" },
                  { label: "MOT", value: "MOT" },
                  { label: "Supply Chain", value: "Supply Chain" },
                  { label: "Administrative", value: "Administrative" },
                  { label: "Facilities", value: "Facilities" },
                  { label: "Medical & Professional", value: "Medical & Professional" },
                ]}
              />
            </Form.Item>
          </Col>

          {/* Home */}
          <Col span={12}>
            <Form.Item
              label="Home Address"
              name="home"
              rules={[{ required: true, message: "Please enter home address" }]}>
              <Input placeholder="Enter home address" />
            </Form.Item>
          </Col>

          {/* Emergency */}
          <Col span={12}>
            <Form.Item
              label="Emergency"
              name="emergency"
              rules={[{ required: true, message: "Please enter emergency" }]}>
              <Input placeholder="Enter emergency" />
            </Form.Item>
          </Col>

          {/* Work */}
          <Col span={12}>
            <Form.Item
              label="Work Address"
              name="work"
              rules={[{ required: true, message: "Please enter work address" }]}>
              <Input placeholder="Enter work address" />
            </Form.Item>
          </Col>
          {/* Contact */}
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[{ required: true, message: "Please enter phone" }]}>
                  <Input placeholder="Enter phone" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ type: "email", message: "Please enter a valid email" }]}>
                  <Input placeholder="Enter email" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Employee Type */}
          <Col span={12}>
            <Form.Item
              label="Employee Type"
              name="employeeType"
              rules={[{ required: true, message: "Please select the employee type" }]}>
              <Select placeholder="Select employee type">
                <Select.Option value="Full-Time">Full-Time</Select.Option>
                <Select.Option value="Part-Time">Part-Time</Select.Option>
                <Select.Option value="Seasonal">Seasonal</Select.Option>
                <Select.Option value="Contractual">Contractual</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Rate */}
          <Col span={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Rate"
                  name="rate"
                  rules={[{ required: true, message: "Please select the rate" }]}>
                  <Select placeholder="Select rate">
                    <Select.Option value="Weekly">Weekly</Select.Option>
                    <Select.Option value="Bi-Weekly">Bi-Weekly</Select.Option>
                    <Select.Option value="Monthly">Monthly</Select.Option>
                    <Select.Option value="Annually">Annually</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Salary"
                  name="salary"
                  rules={[{ message: "Please enter a valid salary" }]}>
                  <Input placeholder="Enter salary" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* Supervisor */}
          <Col span={12}>
            <Form.Item
              label="Manager"
              name="supervisor"
              rules={[{ required: true, message: "Please select supervisor" }]}>
              <Select placeholder="Select supervisor">
                <Select.Option value="Person A">Person A</Select.Option>
                <Select.Option value="Person B">Person B</Select.Option>
                <Select.Option value="Person C">Person C</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end gap-3 mt-4">
          <Button transparent onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditEmployees;
