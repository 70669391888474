import { useEffect, useRef, useState } from "react";
import ExistingTierTab from "./existingTierTab";
import SpecificPrice from "./specificPriceTab";
import axios from "axios";
import { useSelector } from "react-redux";
const Mid = ({
  setRefetch,
  refetch,
  setShowSuccessModal,
  setErr,
  setShowErrorModal,
}) => {
  const [active, setActive] = useState("1");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const token = useSelector((state) => state.token);
  const ProductId = useSelector((state) => state.ProductId);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  //Get Listing
  useEffect(() => {
    // const delay = 1000;
    const delay = 0;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 9,
        skip: (page - 1) * 9,
        page: page,
        sortBy: "createdAt:desc",
        type: active === "2" ? "buyer-specific" : "default",
        // priceSet: active === "1" ? "tier-price" : "specific-price",
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, active]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/inv-payment-terms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setData(res?.data?.rows);
        // setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Tabs = [
    {
      key: "1",
      label: "Default Payment Terms",
      component: (
        <ExistingTierTab
          data={data}
          setRefetch={setRefetch}
          refetch={refetch}
          setShowSuccessModal={setShowSuccessModal}
          setShowErrorModal={setShowErrorModal}
          setErr={setErr}
          page={page}
          setPage={setPage}
        />
      ),
    },
    {
      key: "2",
      label: "Buyer Specific",
      component: (
        <SpecificPrice
          data={data}
          setRefetch={setRefetch}
          refetch={refetch}
          setShowSuccessModal={setShowSuccessModal}
          setShowErrorModal={setShowErrorModal}
          setErr={setErr}
          page={page}
          setPage={setPage}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="">
        <div className="grid grid-cols-2 gap-0 ">
          {Tabs.map((tab) => (
            <button
              className={`${
                tab.key === active
                  ? "border-primary bg-primary font-semibold text-white"
                  : "text-[#B7B7B7] bg-[#ECECEC] font-semibold"
              }  p-3 text-[0.95rem] shadow-md flex items-center justify-center border rounded-t-lg`}
              onClick={() =>
                //   dispatch({ type: "TRANSACTION_TYPE", num: tab.key })
                {
                  setActive(tab.key);
                  setData([]);
                }
              }
            >
              {tab.label}
            </button>
          ))}
        </div>{" "}
        {/* bg-[#F6F4F4] */}
        <div className="rounded-md mb-8  sm:min-h-[360px]  shadow-lg bg-white p-6 ">
          {Tabs.find((tab) => tab.key === active)?.component}
        </div>
      </div>
    </div>
  );
};

export default Mid;
