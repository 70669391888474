import React, { useState, useEffect } from "react";
import back5 from "../../../../imgs/back5.png";
import pic from "../../../../imgs/company.svg";
import { Pagination } from "antd";
import axios from "axios";
import check from "../../../../imgs/add.png";
import { useSelector, useDispatch } from "react-redux";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import SuccessModal from "../../../modals/successModal";
import { useNavigate } from "react-router-dom";
let file;
const Main = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [addModal, setAddModal] = useState(false);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [show1, setShow1] = useState("");
  const [name, setName] = useState("");
  const [description, setDiscription] = useState("");
  const [display, setDisplay] = useState(pic);
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("");
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user);

  const imageHandler = async (e) => {
    file = e.target.files[0];
    console.log("Fileee", file);
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();

    user === "merchant-owner"
      ? bodyFormData.append("files", file)
      : bodyFormData.append("file", file);

    // v1/file/images?fileType=batchImage
    const api =
      user === "merchant-owner"
        ? "v1/file/merch-multiple-images?fileType=eventImage"
        : "v1/file/images?fileType=eventImage";
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/${api}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        user === "merchant-owner"
          ? setUrl(data?.data?.[0]?.url)
          : setUrl(data.data.url);

        e.target.value = null;
      })
      .catch(() => {});
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const pageHandler = (e) => {
    setPage(e);
  };
  //Get Listing
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 9,
        skip: (page - 1) * 9,
        page: page,
        sortBy: "createdAt:desc",
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [refetch, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    const api =
      user === "merchant-owner" ? "v1/event-batch/merch" : "v1/event-batch";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddBatch = () => {
    setAddModal(false);
    const api =
      user === "merchant-owner" ? "v1/event-batch/merch" : "v1/event-batch";
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/${api}`,
        {
          name: name,
          detail: description,
          image: url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setRefetch(!refetch);
        setMessage("Batch Added Successfully");
        setShow(true);
        setAddModal(false);
        setUrl("");
        setDisplay(pic);
        setName("");
        setDiscription("");
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShow1(true);
      });
  };

  return (
    <div className="flex items-center justify-center mt-4 flex-col">
      <div
        className={`${
          user === "merchant-owner"
            ? "md:w-[90%] w-full p-4 "
            : "md:w-[85%] w-full p-4 "
        }`}
      >
        <div
          className="pl-2 pr-2  mt-[1rem] sm:mt-[0rem]"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="flex justify-between items-center mt-4">
              <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
                <img
                  className="sm:w-[1.7rem] w-[1.2rem]"
                  src={back5}
                  alt=""
                  onClick={() => {
                    user === "merchant-owner" ? router("/Merchant/EventDetail") :
                    router("/EventDetails");
                  }}
                />
                <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                  Batch Setting
                </h2>
              </div>

              <button
                className={` text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md`}
                onClick={() => {
                  setAddModal(true);
                }}
              >
                Add Batch
              </button>
            </div>
          </div>
        </div>

        <div className="lg:p-12 bg-slate-50 sm:ml-0 w-full  w-full mt-[2rem] mb-8 bg-slate-50 p-[35px] rounded-2xl relative">
          <div className="grid grid-cols-1 w-full sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {data?.map((item, index) => (
              <div
                id={index}
                onClick={() => {
                  dispatch({ type: "BATCH_DETAILS", num: item });
                  user === "merchant-owner"
                    ? router("/Merchant/EventDetails/BatchDetails")
                    : router("/EventDetails/BatchDetails");
                }}
                className="bg-white flex hover:bg-gray-200 cursor-pointer shadow-md relative p-4 gap-3 rounded-md"
              >
                <div className="">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-16 h-16 rounded-full cursor-pointer"
                  />
                </div>
                <div>
                  <p className="mt-2 text-center cursor-pointer font-semibold">
                    {item.name}
                  </p>
                  <h1 className="text-[0.9rem] font-semibold text-primary capitalize">
                    {item?.eventBatchMemberCount} {"Members"}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`flex justify-center mt-7`}>
          <Pagination
            defaultCurrent={1}
            total={totalPages * 10}
            showSizeChanger={false}
            onChange={pageHandler}
            current={page}
          />
        </div>
      </div>

      <div
        className={`${
          addModal ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl sm:w-[35rem] w-full  pb-6 pt-6 flex flex-col  p-6 bg-white">
          <label className="text-[#596F96] text-[1.2rem] font-semibold mb-2 text-center">
            Add Batch
          </label>
          <div className="flex items-center justify-center mb-4 w-full flex-col mt-2">
            <label className="cursor-pointer relative">
              <div className="relative w-[4.5rem] h-[4.5rem] overflow-hidden border-2 border-[#686262]  rounded-full mb-4 ">
                <img
                  className="object-cover w-[4.5rem] h-[4.5rem]"
                  src={display}
                  alt=""
                />
              </div>

              <div
                className={`bg-primary rounded-full absolute bottom-4 right-2`}
              >
                <img src={check} className="lg:w-7 w-5" alt="" />
              </div>
              <input
                type="file"
                className="hidden"
                accept="image/png, image/gif, image/jpeg"
                onInput={(e) => imageHandler(e)}
              />
            </label>
            <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
              Upload Batch Picture
            </h2>
          </div>
          <div className="w-full mb-2">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder="Batch Name"
              className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="w-full mb-4">
            <label className="text-[#596F96] mb-1 block text-[0.9rem] font-semibold">
              Description <span className="text-red-500">*</span>
            </label>
            <form className="flex items-center justify-between w-full bg-white border-[0.3px] border-gray-300 rounded-md text-black text-center py-1 px-2">
              <textarea
                type="text"
                placeholder="Description"
                className="p-2 rounded-l focus:outline-none"
                style={{ border: "none" }}
                value={description}
                onChange={(e) => setDiscription(e.target.value)}
              />
            </form>
          </div>
          <div className="flex justify-end mt-4">
            <button
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              onClick={() => {
                setAddModal(false);
                setUrl("");
                setDisplay(pic);
                setName("");
                setDiscription("");
              }}
            >
              Cancel
            </button>
            <button
              className="cursor-pointer ml-4 rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
              onClick={() => {
                if (url === "") {
                  setErr("Please Upload Image");
                  setShow1(true);
                } else if (name === "") {
                  setErr("Please Enter Batch Name");
                  setShow1(true);
                } else if (description === "") {
                  setErr("Please Add description");
                  setShow1(true);
                } else {
                  AddBatch();
                  setAddModal(false);
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {show1 && <ErrorModal show={show1} setShow={setShow1} err={err} />}
      {show && <SuccessModal show={show} setShow={setShow} message={message} />}
    </div>
  );
};

export default Main;
