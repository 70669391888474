import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import { Switch } from "antd";
const SpecificPriceTab = ({
  data,
  setShow,
  setErr,
  setRefetch,
  refetch,
  setShowSuccessModal,
  setShowErrorModal,
}) => {
  const token = useSelector((state) => state.token);
  const DeletePrice = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-global-tax/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setErr(`Tax Deleted Successfully`);
        setShowSuccessModal(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  const TierCard = ({ item }) => {
    return (
      <div>
        <div className="bg-[#EEEDED] rounded-lg p-4 flex justify-between items-center shadow-sm  mt-2">
          <div>
            <h2 className="text-[1rem] font-semibold text-gray-900 capitalize">
              {item?.name}{" "}
            </h2>
            <p className="text-primary flex items-center gap-2 cursor-pointer font-semibold text-[0.97rem]">
              {item?.taxPercent}%
            </p>
          </div>
          <div
            className="text-primary flex items-center gap-2 cursor-pointer font-semibold text-[0.97rem]"
            onClick={() => {
              //   dispatch({ type: "EDIT", num: true });
              //   dispatch({ type: "DATA", num: item });
              //   setAddChargesModal(true);
            }}
          >
            
            <DeleteFilled
              style={{ fontSize: "18px", color: "#FF0000" }}
              onClick={() => DeletePrice(item?.id)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 ">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
        {data?.map((item, index) => (
          <TierCard key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default SpecificPriceTab;
