import { Pagination, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import ErrorModal from "../../../Merchant/Modals/errorModal";
let options;
const Paget = () => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [prompt, setPrompt] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [type, setType] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [error, setError] = useState("");
  const eventId = useSelector((state) => state.eventId);
  const token = useSelector((state) => state.token);
  const subEventId = useSelector((state) => state.subEventId);
  const [showError, setShowError] = useState(false);
  const privilege = useSelector((state) => state.myPrivileges);
  const user = useSelector((state) => state.user);
  const columnHeaders = [
    "SR.",
    "Name",
    "GID",
    "Email",
    "Phone",
    "Role",
    "Joining Date/Time",
    "Status",
  ];
  useEffect(() => {
    options = [
      { value: "invited", label: "Invited" },
      { value: "joined", label: "Joined" },
      { value: "leaved", label: "Leaved" },
    ];
  }, [dash]);
  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      const params = {
        limit: 6,
        skip:
          search || searchDob || countryCode || phoneNoo ? 0 : (page - 1) * 6,
        page: search ? pagewithsearch : page,
        sortBy: "createdAt:desc",
        ...(type && { status: type }),
      };
      fetchEmployees(params, newCancelTokenSource);
    }, delay);
    return () => {
      clearTimeout(timeoutId);
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, search, searchDob, countryCode, phoneNoo, pagewithsearch, type]);
  const fetchEmployees = (params, cancelTokenSource) => {
    let url;
    if (dash === "/Event/Invitee" || dash === "/Merchant/Event/Invitee") {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/merch-invitee-list/${eventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/invitee-list/${eventId}`;
      }
      // url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/invitee-list/${eventId}`;
    } else {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/merch-invitee-list/${subEventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/invitee-list/${subEventId}`;
      }
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setAllData(data?.data?.rows);
        setTotalPages(data?.data?.totalPages);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const pageHandler = (e) => {
    if (search) {
      setPagewithsearch(e);
    } else {
      setPage(e);
    }
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  return (
    <>
      <section className="w-full pl-4 pr-4">
        <div className="mx-auto max-w-screen-xl ">
          <div className="relative sm:rounded-lg overflow-hidden ">
            <div className="w-full flex sm:flex-row flex-col-reverse sm:items-center sm:justify-end">
              <div className="w-full flex justify-end flex items-center">
                <div className="w-[10rem]">
                  <Select
                    placeholder="Filter By"
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    value={type}
                    onChange={(value) => setType(value)}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "none",
                    }}
                    className="wow text-gray-900 mb-2 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full"
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto w-full h-full rounded-tl-lg rounded-tr-lg custom-scrollbar">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
                <tr>
                  {columnHeaders.map((columnHeader) => (
                    <th
                      scope="col"
                      className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {allData?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center text-gray-500 text-lg"
                    >
                      <p className="p-10">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {allData.map((data, index) => (
                    <tr className="border-b border-[1px] border-[#d9d9d970] bg-gray-50 hover:bg-[#1BA397] hover:text-white text-md cursor-pointer">
                      <th
                        scope="row"
                        className="px-4 py-3 font-medium text-gray-900  whitespace-nowrap "
                      >
                        {search
                          ? index + 1 + (pagewithsearch - 1) * 6
                          : index + 1 + (page - 1) * 6}
                        .
                      </th>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full border-2 border-primary"
                              src={data?.inviteeUser?.person?.image}
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div className="">
                              {data?.inviteeUser?.person?.firstName}{" "}
                              {data?.inviteeUser?.person?.lastName}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6  py-5">
                        <div className="">
                          {data?.inviteeUser?.gid
                            ? data?.inviteeUser?.gid
                            : "----"}
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {data?.inviteeUser?.email
                          ? data?.inviteeUser?.email
                          : "----"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        {data?.inviteeUser?.phoneNo
                          ? `${data?.inviteeUser?.countryCode} ${data?.inviteeUser?.phoneNo}`
                          : "----"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap capitalize">
                        {data?.inviteeUser?.roles?.length > 0
                          ? data?.inviteeUser?.roles[0]
                          : "----"}
                      </td>
                      <td className="px-4 py-3">
                        {data?.status === "joined" ? (
                          <div>
                            <p>
                              {moment(data?.updatedAt).format("MM/DD/YYYY")}
                            </p>
                            <p>{moment(data?.updatedAt).format("hh:mm A")}</p>
                          </div>
                        ) : (
                          <p>----</p>
                        )}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div
                          className={`border-none ${
                            data?.status === "invited"
                              ? "bg-[#2F84F1]"
                              : data?.status === "leaved"
                              ? "bg-[#D64242]"
                              : " bg-primary"
                          }  p-1 pl-5 pr-5 rounded-md text-center text-white focus:border-none cursor-pointer capitalize`}
                        >
                          {data?.status === "invited"
                            ? "Pending"
                            : data?.status}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <nav
            className="flex bg-white flex-col h-[5.5rem] md:flex-row justify-center items-start md:items-center space-y-3 md:space-y-0 p-4 rounded-bl-lg rounded-br-lg"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500">
              Showing{" "}
              <span className="font-semibold text-gray-900 ">Page {page} </span>
              of{" "}
              <span className="font-semibold text-gray-900 ">{totalPages}</span>
            </span>
            <div className={`flex justify-end mt-7`}>
              <Pagination
                defaultCurrent={1}
                total={totalPages * 10}
                showSizeChanger={false}
                onChange={pageHandler}
                current={page}
              />
            </div>
          </nav>
        </div>
      </section>
      {showError && <ErrorModal err={error} setShow={setShowError} />}
    </>
  );
};

export default Paget;
