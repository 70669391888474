import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import across from "../../../../imgs/across.png";
import { useLocation } from "react-router-dom";
import DepartmentList from "../../../../constants/departmentList";
import SignatureWhite from "../../../../imgs/signatureWhite.png";
import SignatureBlack from "../../../../imgs/signatureBlack.png";
import fingerprintWhite from "../../../../imgs/fingerprintWhite.png";
import gallary from "../../../../imgs/picture.png";
import device from "../../../../imgs/sign.png";
import codeHandler from "../../../Register/codeHandler/codehandle";
import {
  UploadOutlined,
  DeleteFilled,
  PlusCircleFilled,
} from "@ant-design/icons";

let data;
let file;
const EmployerE = () => {
  const { Option } = Select;
  const location1 = useLocation();
  const dash = location1.pathname;
  const profileData = useSelector((state) => state.profileData);
  const dataUser = useSelector((state) => state.dataUser);
  const dispatch = useDispatch();
  const [employer, setEmployer] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [location, setLocation] = useState("");
  const [isLoadingx, setIsLoadingx] = useState("");
  const [show, setShow] = useState("");
  const user = useSelector((state) => state.user);
  const [err, setErr] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [department, setDepartment] = useState(null);
  const token = useSelector((state) => state.token);
  const [signature, setSignature] = useState("");
  const veri = useSelector((state) => state.verify);
  const [sig, setSig] = useState(true);
  const [sigD, setSigD] = useState(false);
  const [gal, setGal] = useState(false);
  const [pop, setPop] = useState(false);
  const [display, setDisplay] = useState("");

  // useEffect(() => {
  //   if (!test) {
  //     setTest(true);
  //   } else {
  //     setTest(false);
  //   }
  // }, [data]);

  //Get subroles of employee
  useEffect(() => {
    const api =
      user === "admin"
        ? "v1/privileges/list-roles-privileges/by-admin"
        : "v1/privileges/list-roles-privileges/by-superadmin";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        const rolesWithLabelValue = data?.data?.roles
          ?.map((role) => ({
            label: role.charAt(0).toUpperCase() + role.slice(1),
            value: role,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        // const rolesWithLabelValue = data?.data?.roles?.map(role => ({ label: role, value: role }));
        setRoles(rolesWithLabelValue);
      })
      .catch(() => {});
  }, []);

  //Get deparmtnet locations and warehouse
  useEffect(() => {
    const api = "v1/mhsss-facility";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
        },
      })
      .then((data) => {
        const filteredData = data?.data?.rows.filter(
          (item) => item.type === "department"
        );
        // const dep = filteredData?.map((dep) => ({
        //   label:
        //     dep?.name +
        //     "" +
        //     (dep?.type === "master-warehouse"
        //       ? "    (Master Warehouse)"
        //       : `    (${
        //           dep?.type.charAt(0).toUpperCase() + dep?.type.slice(1)
        //         }) `),
        //   value: dep?.id,
        // }));
        // console.log("dep", dep);
        setDepartment(filteredData);
      })

      .catch(() => {});
  }, []);
  useEffect(() => {
    if (profileData.employeeInfo) {
      data = profileData.employeeInfo;
      setEmployer(data?.employer);
      setSelectedRole(data?.role);
      setSupervisor(data?.supervisor);
      setLocation(data?.mhsssfacilityId);
      setSignature(data?.signature);
      const body = [data?.signature];
      if (data?.signature) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/v1/file/get-image-url?fileType=document`,
            {
              fileNames: body,
            },
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then((data) => {
            setDisplay(data.data[0].url);
          })
          .catch(() => {});
      }
    }
  }, [profileData]);

  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoadingx(true);
    if (profileData.employeeInfo === null) {
      const api =
        user === "admin"
          ? "v1/employee-info/by-admin?employeeUserId"
          : "v1/employee-info/by-superadmin?employeeUserId";
      const data = {
        employer,
        supervisor,
        // departmentOrLocation: location,
        mhsssfacilityId: location,
        role: selectedRole,
        signature,
      };
      if (selectedRole === "") {
        delete data.role;
      }
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/${api}=${profileData.user.id}`,
          {
            employeeInfo: data,
            consumerInfo: {
              ip: "111.222.33.4",
              location: "329.3323, 434.343232",
            },
          },
          {
            headers: {
              Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: "RESET" });
          setIsLoadingx(false);
        })
        .catch((err) => {
          setIsLoadingx(false);
          setErr(err.response.data.message);
          setShow(true);
        });
    } else {
      const api =
        user === "admin"
          ? "v1/employee-info/by-admin?employeeUserId"
          : "v1/employee-info/by-superadmin?employeeUserId";
      const data = {
        employer,
        supervisor,
        // departmentOrLocation: location,
        mhsssfacilityId: location,
        role: selectedRole,
        signature,
      };

      if (
        selectedRole === "" ||
        selectedRole === null ||
        selectedRole === undefined
      ) {
        setErr("Role is required");
        setShow(true);
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/${api}=${profileData.user.id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
              },
            }
          )
          .then(() => {
            dispatch({ type: "RESET" });
            setIsLoadingx(false);
          })
          .catch((err) => {
            setIsLoadingx(false);
            setErr(err.response.data.message);
            setShow(true);
          });
      }
    }
  };

  const downloadFile = () => {
    const url = "https://sap-api-staging.jabcomp.gy/public/biometric-sdk.exe";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "biometric-sdk.exe");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function GetWhitSigPic() {
    var json = { action: "06" };
    var encode = codeHandler?.encode(JSON.stringify(json), "base64");
    console.log("encode", encode);

    // Create a FormData object to send the data
    var formData = new FormData();
    formData.append("data", encode);

    axios
      .post(`http://localhost:28815/`, encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // Handle success response
        console.log("Success:", response.data);
      })
      .catch(function (error) {
        console.log("Error:", error);
      });
  }

  function ConfirmSign() {
    var json = { action: "07" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        console.log("Test", response);
        // setDisplay("data:image/png;base64," + response?.data?.SigPicBase64);
        const base64Str = response?.data?.SigPicBase64;
        const file = new File(
          [Uint8Array.from(atob(base64Str), (m) => m.codePointAt(0))],
          "myfilename.jpeg",
          { type: "image/jpeg" }
        );
        console.log("Tester", file);
        imageHandlerSig(file)();
      })
      .catch(function () {});
  }

  function ReSign() {
    var json = { action: "08" };
    var encode = codeHandler.encode(JSON.stringify(json), "base64");
    axios
      .post("http://localhost:28815/", encode, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function () {
        setDisplay("");
      })
      .catch(function () {});
  }

  const imageHandler = (setState, setUrl, load) => async (e) => {
    setPop(false);
    setSigD(false);
    // load(true);
    file = e.target.files[0];
    console.log("Fileee", file);
    if (file === undefined) {
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      console.log("base", base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          setGal(true);
          load(false);
        })
        .catch(() => {});
    }
  };

  const imageHandlerSig = (file) => async () => {
    console.log("OKKKK");
    const base641 = await convertToBase64(file);
    setDisplay(base641);
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=document`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        console.log("Url", data);
        setSignature(data.data.url);
      })
      .catch(() => {});
  };

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const options = department?.map((item) => ({
    value: item?.id,
    label: item?.name,
    address: [
      item?.lot,
      item?.address,
      item?.regionNo,
      item?.regionName,
      item?.city,
      item?.ward,
    ]
      .filter(Boolean)
      .join(", "),
  }));

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex flex-col">
        <h1 className="font-semibold text-gray-700 text-[0.95rem]">
          {option?.label}
        </h1>
        <p className="text-sm text-gray-500">{option?.address}</p>
      </div>
    </Option>
  );
  return (
    <div>
      <form onSubmit={submitHandler}>
        <h2 className="mb-4 text-[1.1rem] font-semibold">Employer:</h2>
        <input
          type="text"
          name=""
          placeholder="Employer"
          value={employer}
          onChange={(e) => setEmployer(e.target.value)}
          // onChange={handleChange(setEmployer)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Supervisor:</h2>
        <input
          type="text"
          name=""
          placeholder="Supervisor"
          value={supervisor}
          onChange={handleChange(setSupervisor)}
          className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[#F7F7F7] rounded-[0.5rem] p-2 mb-4 "
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">Role:</h2>
        <Select
          placeholder="Role"
          optionFilterProp="children"
          style={{ marginBottom: "1.2rem" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          className="wow"
          onChange={setSelectedRole}
          options={roles}
          value={selectedRole !== "" ? selectedRole : null}
        />
        <h2 className="mb-4 text-[1.1rem] font-semibold">
          Department/Location:
        </h2>
        {/* <Select
          placeholder="Department/Location"
          optionFilterProp="children"
          style={{ marginBottom: "1.2rem" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="middle"
          className="wow"
          onChange={(value) => {
            setLocation(value);
          }}
          // options={DepartmentList}
          options={department}
          value={location !== "" ? location : null}
        /> */}
        <Select
          placeholder="Department/Location"
          // className="wow w-[100%] mb-4"
          className="w-[100%] woww mb-4"
          renderOption={renderOption}
          onChange={(value) => {
            setLocation(value);
          }}
          value={location !== "" ? location : null}
        >
          {options?.map(renderOption)}
        </Select>
        <h2 className="mb-4 text-[1.1rem] font-semibold">Signature</h2>
        <label
          onClick={() => setPop(true)}
          htmlFor="special-input"
          className={` cursor-pointer relative`}
        >
          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
            <div>
              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
            </div>
            <p className="text-[#9CA6C2] ml-2">Upload Signature</p>
          </div>
          {/* <input
            type="file"
            className="hidden w-[100%] cursor-poniter"
            id="special-input"
            accept="image/png, image/gif, image/jpeg"
            onInput={imageHandler}
          /> */}
        </label>
        <div className="flex flex-col items-center">
          <div className="w-[25rem] h-[13rem]">
            {display && (
              <img
                className={`w-[25rem] h-[13rem]`}
                src={display}
                alt="signature"
              />
            )}
          </div>
        </div>
        {sigD && (
          <div
            className="flex gap-5 sm:w-[23rem] w-[18rem]"
            style={{
              padding: "10px",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            <button
              type="button"
              onClick={() => {
                GetWhitSigPic();
              }}
              className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
            >
              Open Pad
            </button>
            <button
              type="button"
              onClick={() => {
                ConfirmSign();
              }}
              className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
            >
              Get Sign
            </button>
            <button
              type="button"
              onClick={() => {
                ReSign();
              }}
              className="border p-2 bg-primary w-[33%] rounded-md font-semibold text-white"
            >
              Reset
            </button>
          </div>
        )}
        {sigD && (
          <div
            className="flex gap-5 sm:w-[23rem] w-[18rem]"
            style={{
              padding: "10px",
              textAlign: "center",
              display: "inline-block",
            }}
          >
            <p
              onClick={downloadFile}
              className="text-primary hover:underline cursor-pointer font-semibold"
            >
              Download SDK
            </p>
          </div>
        )}
        <button
          onClick={submitHandler}
          className={`${
            (profileData.user.status === "pending" ||
              dataUser?.data?.user?.status === "verified" ||
              dataUser?.data?.user?.status === "re-submitted" ||
              dataUser?.data?.user?.status === "deferred" ||
              dataUser?.data?.user?.status === "in-review") &&
            dash === "/Dashboard/PensionerAccount"
              ? "hidden"
              : ""
          } relative lg:text-[1.1rem] mt-8 bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          {/* <Spin
            style={{ display: `${isLoadingx ? "" : "none"}` }}
            indicator={antIcon}
          /> */}
          <span>Save</span>
          <div
            className={`w-[100%] h-[100%] ${
              isLoadingx ? "" : "hidden"
            } absolute top-0 left-0 bg-slate-400 opacity-40 rounded-2xl`}
          ></div>
        </button>
      </form>

      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>

      <div
        className={`${
          pop ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[25rem] pb-6 flex items-center p-3 bg-white flex-col">
          <h1 className="text-xl text-primary font-semibold">Choose</h1>
          <div className="w-[50%] flex items-center justify-between mt-10 mb-10">
            {/* <img
              onClick={ConfirmSig}
              src={figA}
              alt=""
              className="w-14 h-14 cursor-pointer"
            /> */}
            <img
              onClick={() => {
                setSigD(true);
                setPop(false);
              }}
              src={device}
              alt=""
              className="w-18 h-14 cursor-pointer"
            />
            <label>
              <img src={gallary} alt="" className="w-14 h-14 cursor-pointer" />
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={imageHandler(setDisplay, setSignature)}
              />
            </label>
          </div>
          <div className="flex items-center justify-end w-[80%]">
            <button
              onClick={() => setPop(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerE;
