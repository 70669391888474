import React, { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
import { useDispatch } from "react-redux";
import ReportMain from "./ReportMain";

const Reports = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "9" });
  }, []);

  return (
    <AttendanceDashboardWrapper>
      <ReportMain />
    </AttendanceDashboardWrapper>
  );
};

export default Reports;
