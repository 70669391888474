import React, { useState } from "react";
import { Dropdown, Menu, Pagination } from "antd";

const AttendanceTable = ({ data, columns, pageSize = 6, actions }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredData = data?.filter((item) =>
    columns.some((column) =>
      item[column.key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="">
      <div className="overflow-x-auto custom-scrollbar">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
            <tr>
              {columns.map((col) => (
                <th
                  key={col.key}
                  className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap">
                  {col.title}
                </th>
              ))}
              {actions && (
                <th className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length + (actions ? 1 : 0)}
                  className="text-center text-gray-500 text-lg">
                  <p className="p-10">No records found</p>
                </td>
              </tr>
            ) : (
              currentData.map((record, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-[#f2fcfc] border-b border-[#d9d9d970] hover:bg-gray-100 text-md">
                  {columns.map((col) => (
                    <td key={col.key} className="px-4 py-2 whitespace-nowrap">
                      {col.render
                        ? col.render(record[col.dataIndex], record)
                        : record[col.dataIndex]}
                    </td>
                  ))}

                  {actions && (
                    <td className="px-4 py-2 whitespace-nowrap">
                      <Dropdown overlay={actions} trigger={["click"]}>
                        <button className="text-primary bg-white border border-gray-300 px-3 py-1 rounded-md cursor-pointer">
                          Actions
                        </button>
                      </Dropdown>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="font-semibold pl-1 text-gray-600">
          Showing <b className="text-primary">{startIndex + 1}</b> to{" "}
          <b className="text-primary">{endIndex}</b> of{" "}
          <b className="text-primary">{filteredData.length}</b> entries
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredData.length}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default AttendanceTable;
