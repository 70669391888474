import React, { useEffect, useState } from "react";
import loc from "../../../imgs/loc.png";
import group from "../../../imgs/group.png";
import logo from "../../../imgs/logo12.png";
import guest from "../../../imgs/guests.svg";
import { Button, Pagination } from "antd";
import { FaTimes } from "react-icons/fa";
import moment from "moment";
import { RightOutlined } from "@ant-design/icons";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const EventDetails = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const eventId = useSelector((state) => state.eventId);
  const token = useSelector((state) => state.token);
  const [totalPages, setTotalPages] = useState(0);
  const dataUser = useSelector((state) => state.dataUser);
  const subEventId = useSelector((state) => state.subEventId);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const dash = location.pathname;
  const [countryCode, setCountryCode] = useState("");
  const [batchId, setBatchId] = useState("");
  const [phoneNoo, setPhoneNoo] = useState("");
  const [invitee, setInvitee] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupVerify, setShowPopupVerify] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [status, setStatus] = useState("");
  const [joined, setJoined] = useState(0);
  const [showDone, setShowDone] = useState(false);
  const [showInviteDone, setShowInvteDone] = useState(false);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [event, setEvent] = useState("");
  const [join, setJoin] = useState(false);
  const [phone, setPhone] = useState("");
  const [contactMethod, setContactMethod] = useState("gid");
  const [inviteMethod, setInviteMethod] = useState("individual");
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [batch, setBatch] = useState([]);
  const [sub, setSub] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [showInviteeBatch, setShowInviteeBatch] = useState(false);
  const [gid, setGid] = useState("");
  const handleOptionChange = (event) => {
    setContactMethod(event.target.value);
    if (event.target.value === "phone") {
      setEmailValue("");
      setGid("");
    }
    if (event.target.value === "email") {
      setGid("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "gid") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "import") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "batch") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
  };
  const handleInviteMethod = (event) => {
    setInviteMethod(event.target.value);
  };

  const userId = dataUser?.data?.user
    ? dataUser?.data?.user.id
    : dataUser?.data?.merchuser?.id;

  const hostId = event?.hostUserId ? event?.hostUserId : event?.hostMerchUserId;

  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPhone(event);
      if (event) {
        const wow = parsePhoneNumber(event);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    } else {
      setState(event.target.value);
    }
  };
  const containerStyle = {
    width: "100%",
    height: "250px",
  };
  useEffect(() => {
    if (event?.location) {
      const [lat, lng] = event.location.split(", ").map(parseFloat);
      setCenter({ lat, lng });
    }
  }, [event]);
  useEffect(() => {
    let url;
    if (dash === "/SubEventDetails" || dash === "/Merchant/SubEventDetail") {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/merch-event/${subEventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${subEventId}`;
      }
      // url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${subEventId}`;
    } else {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/merch-event/${eventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${eventId}`;
      }
      // url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${eventId}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setEvent(data?.data?.event);
        setJoined(`${data?.data?.eventJoineeCount}`);
        setSub(`${data?.data?.subEventCount}`);
        setJoin(data?.data?.eventInviteJoineeStatus);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fetch]);
  useEffect(() => {
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 9,
        skip: (page - 1) * 9,
        page: page,
        sortBy: "createdAt:desc",
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [fetch, page]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    const api =
      user === "merchant-owner" ? "v1/event-batch/merch" : "v1/event-batch";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        setBatch(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const checkInviteeStatus = () => {
      const userId = dataUser?.data?.user?.id;
      if (userId && event?.eventinvitees?.length > 0) {
        const invitee = event.eventinvitees.find(
          (invitee) => invitee.inviteeUserId === userId
        );
        if (invitee) {
          setStatus(invitee.status);
        } else {
          console.log("NotFound");
          setStatus("no");
        }
      } else {
        console.log("Not Found first");
        setStatus("no");
      }
    };
    if (event !== "") {
      checkInviteeStatus();
    }
  }, [event]);
  const Join = (id, type) => {
    let url;
    let method;
    if (event?.type === "private") {
      // url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-private-event`;
      url =
        user === "merchant-owner"
          ? `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-merch-private-event`
          : `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-private-event`;
      method = "put";
    } else if (event?.type === "public") {
      // url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-public-event`;
      url =
        user === "merchant-owner"
          ? `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-merch-public-event`
          : `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-public-event`;
      method = "post";
    }
    axios[method](
      url,
      {
        eventId: id,
        status: type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(() => {
        setFetch(!fetch);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Delete = () => {
    let url;
    if (dash === "/SubEventDetails" || dash === "/Merchant/SubEventDetail") {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/merch-event/${subEventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/delete-event/${subEventId}`;
      }
    } else {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/merch-event/${eventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event/delete-event/${eventId}`;
      }
    }
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setFetch(!fetch);
        if (user === "merchant-owner") {
          if (dash === "/Merchant/EventDetail") {
            router("/MerchantDashBoard/events");
          } else {
            router("/MerchantDashBoard/Subevents");
          }
          return;
        }
        dash === "/EventDetails"
          ? router("/EmployeeDashBoard/events")
          : router("/EmployeeDashBoard/Subevents");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Invite = () => {
    if (invitee === "") {
      setErr("Invitee Name Is Required");
      setShow(true);
    } else if (contactMethod === "gid" && gid === "") {
      setErr("GID Is Required");
      setShow(true);
    } else if (contactMethod === "email" && emailValue === "") {
      setErr("Email Is Required");
      setShow(true);
    } else if (contactMethod === "phone" && phoneNoo === "") {
      setErr("Phone Is Required");
      setShow(true);
    } else {
      let body = {
        eventId:
          dash === "/SubEventDetails" || dash === "/Merchant/SubEventDetail"
            ? subEventId
            : eventId,
        // status: "invited",
        name: invitee,
      };
      if (contactMethod === "gid") body.gid = gid;
      else if (contactMethod === "email") body.email = emailValue;
      else if (contactMethod === "phone") {
        body.countryCode = `+${countryCode}`;
        body.phoneNo = phoneNoo;
      }
      const api =
        user === "merchant-owner"
          ? "v1/event-invitee/merch-invitee-event-users"
          : "v1/event-invitee/invitee-event-users";
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/${api}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // router(`/event/invite/:userId/:eventId`)
          setShowPopup(false);
          setShowInvteDone(true);
          setErr("Invitation Sent.");
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    }
  };
  const Verify = () => {
    // if (invitee === "") {
    //   setErr("Invitee Name Is Required");
    //   setShow(true);
    // } else
    if (contactMethod === "gid" && gid === "") {
      setErr("GID Is Required");
      setShow(true);
    } else if (contactMethod === "email" && emailValue === "") {
      setErr("Email Is Required");
      setShow(true);
    } else if (contactMethod === "phone" && phoneNoo === "") {
      setErr("Phone Is Required");
      setShow(true);
    } else {
      let body = {
        eventId:
          dash === "/SubEventDetails" || dash === "/Merchant/SubEventDetail"
            ? subEventId
            : eventId,
        // status: "invited",
        // name: invitee,
      };
      if (contactMethod === "gid") body.gid = gid;
      else if (contactMethod === "email") body.email = emailValue;
      else if (contactMethod === "phone") {
        body.countryCode = `+${countryCode}`;
        body.phoneNo = phoneNoo;
      }

      const api =
        user === "merchant-owner"
          ? "v1/event-invitee/merch-verify-event-users"
          : "v1/event-invitee/verify-event-users";
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/${api}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setShowPopup(false);
          setShowInvteDone(true);
          setErr("Verified Successfully.");
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    }
  };
  const InviteBatch = () => {
    setShowInviteeBatch(false);
    let url;
    if (dash === "/SubEventDetails" || dash === "/Merchant/SubEventDetail") {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-batch/merch-invitee/${batchId}?eventId=${subEventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-batch/invitee/${batchId}?eventId=${subEventId}`;
      }
    } else {
      if (user === "merchant-owner") {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-batch/merch-invitee/${batchId}?eventId=${eventId}`;
      } else {
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-batch/invitee/${batchId}?eventId=${eventId}`;
      }
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setShowInvteDone(true);
        setErr("Invitation Sent.");
      })
      .catch((err) => {
        if (err?.response?.data?.message === "event batch list not found") {
          setErr("No member added to this batch.");
        } else setErr(err?.response?.data?.message);
        setShow(true);
      });
  };
  const pageHandler = (e) => {
    setPage(e);
  };
  useEffect(() => {
    const currentTime = new Date();
    const eventTime = new Date(event.startDateTime);
    const timeDifference = eventTime - currentTime;
    const fourHoursInMilliseconds = 5.47 * 60 * 60 * 1000;
    if (timeDifference < 0) {
      setShowDelete(true);
    } else if (timeDifference < fourHoursInMilliseconds) {
      setShowDelete(false);
    } else {
      setShowDelete(true);
      const timeoutId = setTimeout(() => {
        setShowDelete(false);
      }, timeDifference - fourHoursInMilliseconds);

      return () => clearTimeout(timeoutId);
    }
  }, [event.startDateTime]);

  const isEventOver = (start) => {
    const currentTime = new Date();
    const eventStartTime = new Date(start);
    return eventStartTime < currentTime;
  };
  return (
    <div className="flex w-full pb-[5rem] items-center justify-center">
      <div className="w-full flex flex-col mt-4 w-full ">
        <div className="w-full flex items-center justify-between">
          <h1 className="text-2xl font-semibold ">
            {event?.name}{" "}
            <span
              className={`${
                event?.type === "private" ? "bg-[#6F42C1]" : "bg-[#3395FE]"
              } sm:ml-3 text-sm rounded-full pl-4 p-1 pr-4 text-white`}
            >
              {event?.type}
            </span>
          </h1>
          {userId === hostId && (
            <div className="flex gap-4">
              <h1
                onClick={() => {
                  if (user === "merchant-owner") {
                    dash === "/Merchant/EventDetail"
                      ? router("/Merchant/EventUpdate")
                      : router("/Merchant/SubEventUpdate");
                    return;
                  }
                  dash === "/EventDetails"
                    ? router("/eventUpdate")
                    : router("/SubEventUpdate");
                }}
                className="text-lg cursor-pointer w-[6rem] bg-green-100 px-3 flex items-center justify-center rounded-md font-semibold text-primary hover:underline"
              >
                Edit
              </h1>
              {showDelete && (
                <h1
                  onClick={() => {
                    setShowDone(true);
                  }}
                  className="text-lg px-3 rounded-md w-[6rem] bg-red-100 flex items-center justify-center cursor-pointer font-semibold text-red-600 hover:underline"
                >
                  Delete
                </h1>
              )}
            </div>
          )}
        </div>
        <div className="flex sm:gap-10 gap-4 mt-2">
          <div className="flex gap-2">
            <img src={loc} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">{event?.address}</p>
          </div>
          <div className="flex gap-2">
            <img src={group} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">
              {event?.allowedPerson} Guest capacity
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-end mt-4">
          <Button
            onClick={() => {
              if (user === "merchant-owner") {
                if (dash === "/Merchant/EventDetail") {
                  router("/Merchant/Event/Gallery");
                } else {
                  router("/Merchant/SubEvent/Gallery");
                }
                return;
              }
              dash === "/EventDetails"
                ? router("/Event/Gallery")
                : router("/SubEvent/Gallery");
            }}
            className="border border-dotted bg-gray-50 hover:bg-gray-200 cursor-pointer text-black font-semibold"
          >
            See All Photos
          </Button>
        </div>
        <div
          key={event.id}
          className="w-full flex md:flex-row flex-col gap-2 mt-2"
        >
          <div className="md:w-[60%] w-full h-[320px]">
            <img
              className="w-full h-full rounded-xl border bg-gray-50"
              src={event?.coverImage}
              alt={event?.id}
            />
          </div>
          {event?.eventgallaries?.length > 0 && (
            <div className="md:w-[40%] w-full h-[320px] grid grid-cols-2 grid-rows-2 gap-2">
              {event?.eventgallaries?.length > 0 && (
                <img
                  className="w-full h-full rounded-xl border bg-gray-50"
                  src={event?.eventgallaries[0]?.image}
                  alt={event?.eventgallaries[0]?.id}
                />
              )}
              {event?.eventgallaries?.length > 1 && (
                <img
                  className="w-full h-full rounded-xl border bg-gray-50"
                  src={event?.eventgallaries[1]?.image}
                  alt={event?.eventgallaries[1]?.id}
                />
              )}
              {event?.eventgallaries?.length > 2 && (
                <img
                  className="w-full h-full rounded-xl border bg-gray-50"
                  src={event?.eventgallaries[2]?.image}
                  alt={event?.eventgallaries[2]?.id}
                />
              )}
              {event?.eventgallaries?.length > 3 && (
                <img
                  className="w-full h-full rounded-xl border bg-gray-50"
                  src={event?.eventgallaries[3]?.image}
                  alt={event?.eventgallaries[3]?.id}
                />
              )}
            </div>
          )}
        </div>
        <div className="w-full flex md:flex-row flex-col gap-4">
          <div className="mt-6 md:w-[80%] w-full">
            <div className="flex sm:flex-row flex-col sm:gap-6 gap-2 mt-1 mb-3">
              <div className="flex gap-2 mt-1 mb-2">
                <img
                  src={
                    event?.hostUser
                      ? event?.hostUser?.person?.image
                      : event?.hostMerchUser?.merchperson?.image
                  }
                  alt=""
                  className="w-[58px] h-[54px] border border-gray-50 rounded-lg"
                />
                <div>
                  <p className="font-semibold text-gray-700">
                    {event?.hostUser
                      ? event?.hostUser?.person?.firstName
                      : event?.hostMerchUser?.merchperson?.firstName}{" "}
                    {event?.hostUser
                      ? event?.hostUser?.person?.lastName
                      : event?.hostMerchUser?.merchperson?.lastName}
                  </p>
                  <p className="font-semibold text-gray-400">Organizer</p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="px-2 rounded-lg bg-secondary">
                  <img src={guest} alt="" className="w-12 h-12" />
                </div>
                <div className="font-semibold w-full items-center justify-between">
                  <b>{joined}</b>
                  <div className="flex gap-2">
                    <p>People Joined</p>
                    <p
                      onClick={() => {
                        if (user === "merchant-owner") {
                          if (dash === "/Merchant/EventDetail") {
                            router("/Merchant/Event/Invitee");
                          } else {
                            router("/Merchant/SubEvent/Invitee");
                          }
                          return;
                        }
                        dash === "/EventDetails"
                          ? router("/Event/Invitee")
                          : router("/SubEvent/Invitee");
                      }}
                      className="text-primary cursor-pointer hover:underline"
                    >
                      View Details
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h1 className="font-semibold text-gray-800 text-lg">Description</h1>
            <p className="mb-10">{event?.detail}</p>
            <LoadScript googleMapsApiKey="AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="md:w-[20%] w-full p-6 flex flex-col items-center justify-center rounded-xl bg-gray-100 mt-6">
            <p className="font-semibold w-full flex items-center justify-between">
              <p>Allowed members</p>
              <b className="underline">{event?.allowedPerson}</b>
            </p>
            <p className="font-semibold w-full flex items-center justify-between">
              <p>Joined members</p>
              <b className="underline text-red-600">{joined}</b>
            </p>
            <div className="w-full mt-4">
              {/* <p className="pl-3 text-center font-semibold pr-3">
                From
              </p> */}
              <p className="pl-2 font-semibold pr-2 text-center whitespace-nowrap bg-secondary rounded-full">
                {moment(event?.startDateTime).format("MM/DD/YYYY, h:mm a")}
              </p>
              <p className="pl-3 text-center font-semibold pr-3">-</p>
              <p className="pl-2 text-center font-semibold pr-2 bg-secondary whitespace-nowrap rounded-full">
                {moment(event?.endDateTime).format("MM/DD/YYYY, h:mm a")}
              </p>
            </div>
            <div className="flex flex-col w-[70%] gap-6 mt-5">
              {userId === hostId ? (
                <p className="text-primary font-bold w-full text-center cursor-pointer hover:underline">
                  Created by you
                </p>
              ) : event?.allowedPerson == joined && !join ? (
                <p className="text-red-600 font-bold  w-full text-center cursor-pointer hover:underline">
                  All Slots Filled
                </p>
              ) : isEventOver(event?.startDateTime) ? (
                <p className="text-red-600 font-bold  w-full text-center">
                  Joining Closed
                </p>
              ) : !join ? (
                <button
                  type=" button"
                  onClick={() => {
                    Join(event.id, "joined");
                  }}
                  className={`text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Join Now
                </button>
              ) : (
                <button
                  type=" button"
                  onClick={() => {
                    Join(event?.id, "leaved");
                  }}
                  className={`text-white w-full bg-red-600 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Leave
                </button>
              )}
              {userId === hostId && (
                <button
                  onClick={() => {
                    setShowPopup(true);
                  }}
                  className={`${
                    event?.type === "private" ? "" : "hidden"
                  } text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Invite
                </button>
              )}
              {userId === hostId && !showDelete && (
                <button
                  onClick={() => {
                    setShowPopupVerify(true);
                  }}
                  className={`${
                    event?.type === "private" ? "" : "hidden"
                  } text-white w-full bg-primary pl-2 pr-2 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Verify Joinees
                </button>
              )}
            </div>
            {!event?.parentEventId && (
              <div className="mt-4">
                <p
                  onClick={() => {
                    if (user === "merchant-owner") {
                      router("/MerchantDashBoard/Subevents");
                      return;
                    }
                    // dispatch({ type: "SUB" });
                    if (user === "pensioner")
                      router("/PensionerDashBoard/Subevents");
                    else router("/EmployeeDashBoard/Subevents");
                  }}
                  className="font-semibold bg-gray-50 text-lg px-4 py-1 text-primary cursor-pointer hover:bg-gray-200 flex items-center gap-6 w-full justify-between"
                >
                  <span>{`${sub} Sub Events`}</span>
                  <span className="ml-2 text-primary flex items-center justify-center">
                    <RightOutlined className="font-semibold" />
                  </span>
                  {/* Right arrow */}
                </p>
              </div>
            )}
            {/* {!sub && (
              <div className="mt-2">
                <p
                  onClick={() => {
                    router("/addSubEvent");
                  }}
                  className="font-semibold  text-primary cursor-pointer hover:underline"
                >
                  Add Sub Event
                </p>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-50">
          <div className="my-4 relative sm:w-2/5 w-full py-8 gap-3 px-6 bg-[#F7F7F7] max-h-[90%] overflow-auto rounded-md">
            <FaTimes
              onClick={() => {
                setShowPopup(false);
                setInvitee("");
                setGid("");
                setCountryCode("");
                setPhoneNoo("");
                setEmailValue("");
              }}
              className="absolute top-2 right-2 text-red-600 text-lg cursor-pointer"
            />
            <h1 className="font-semibold text-[1.8rem] mb-4 w-full text-center">
              Invite
            </h1>
            <div className="flex gap-4 items-center mb-2">
              {" "}
              <div className="mb-2 flex items-center">
                <input
                  type="radio"
                  id="individualOption"
                  name="inviteMethod"
                  value="individual"
                  className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                  checked={inviteMethod === "individual"}
                  onChange={handleInviteMethod}
                />
                <label
                  htmlFor="emailOption"
                  className="cursor-pointer text-sm inline-flex items-center ml-2"
                >
                  Individual
                </label>
              </div>
              <div className="mb-2 flex items-center">
                <input
                  type="radio"
                  id="batchOption"
                  name="inviteMethod"
                  value="batch"
                  className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                  checked={inviteMethod === "batch"}
                  onChange={handleInviteMethod}
                />
                <label
                  htmlFor="emailOption"
                  className="cursor-pointer text-sm inline-flex items-center ml-2"
                >
                  Batch
                </label>
              </div>
            </div>
            {inviteMethod === "individual" && (
              <div className="w-full">
                <input
                  type="text"
                  name=""
                  value={invitee}
                  onChange={(e) => setInvitee(e.target.value)}
                  placeholder="Invitee Name"
                  className="lg:text-[1.1rem] w-[100%] border border-gray-300 mb-4  bg-white rounded-[0.5rem] p-2"
                />
                <div className="flex gap-4">
                  <div className="mb-2 flex items-center">
                    <input
                      type="radio"
                      id="gidOption"
                      name="contactMethod"
                      value="gid"
                      className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                      checked={contactMethod === "gid"}
                      onChange={handleOptionChange}
                    />
                    <label
                      htmlFor="gidOption"
                      className="cursor-pointer text-sm inline-flex items-center ml-2"
                    >
                      GID
                    </label>
                  </div>
                  <div className="mb-2 flex items-center">
                    <input
                      type="radio"
                      id="phoneOption"
                      name="contactMethod"
                      value="phone"
                      className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                      checked={contactMethod === "phone"}
                      onChange={handleOptionChange}
                    />
                    <label
                      htmlFor="phoneOption"
                      className="cursor-pointer text-sm inline-flex items-center ml-2"
                    >
                      Phone Number
                    </label>
                  </div>

                  <div className="mb-2 flex items-center">
                    <input
                      type="radio"
                      id="emailOption"
                      name="contactMethod"
                      value="email"
                      className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                      checked={contactMethod === "email"}
                      onChange={handleOptionChange}
                    />
                    <label
                      htmlFor="emailOption"
                      className="cursor-pointer text-sm inline-flex items-center ml-2"
                    >
                      Email
                    </label>
                  </div>
                </div>

                {contactMethod === "gid" && (
                  <div className="w-full mb-3 mt-2">
                    <input
                      type="text"
                      name=""
                      onChange={(e) => setGid(e.target.value)}
                      placeholder="Enter GID"
                      className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                    />
                  </div>
                )}
                {contactMethod === "phone" && (
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="GY"
                    international
                    value={phone}
                    onChange={handleChange(setPhone, "tt")}
                    countryCallingCodeEditable={false}
                    className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                  />
                )}

                {contactMethod === "email" && (
                  <div className="w-full mb-3 mt-2">
                    <input
                      type="text"
                      name=""
                      value={emailValue}
                      onChange={(e) => setEmailValue(e.target.value)}
                      placeholder="Enter Email"
                      className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                    />
                  </div>
                )}

                <div className="w-full flex items-center mt-8 gap-4 justify-center">
                  <button
                    onClick={() => {
                      setShowPopup(false);
                      setInvitee("");
                      setGid("");
                      setCountryCode("");
                      setPhoneNoo("");
                      setEmailValue("");
                    }}
                    className={`text-white w-[40%] bg-red-600 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={Invite}
                    className={`text-white w-[40%] bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                  >
                    Invite
                  </button>
                </div>
              </div>
            )}
            {inviteMethod === "batch" && (
              <div className="flex flex-col items-center justify-center">
                <div className="w-full p-2 bg-[#D9EFED] shadow-md rounded-md ">
                  <div className="w-full flex items-center justify-end">
                    <h2
                      onClick={() => {
                        if (user === "merchant-owner") {
                          router("/Merchant/EventDetails/Batch");
                          return;
                        }
                        router("/EventDetails/Batch");
                      }}
                      className="flex items-center justify-center hover:underline cursor-pointer text-primary font-semibold"
                    >
                      + Create New
                    </h2>
                  </div>
                  <div className="grid grid-cols-4 gap-4 pb-4 mt-4">
                    {batch.map((item) => (
                      <div key={item.id} className="flex flex-col items-center">
                        <img
                          onClick={() => {
                            if (item?.eventBatchMemberCount === 0) {
                              setErr(
                                "There are no members in this batch at the moment."
                              );
                              setShow(true);
                            } else {
                              setBatchId(item?.id);
                              setShowInviteeBatch(true);
                            }
                          }}
                          src={item.image}
                          alt={item.name}
                          className="w-16 h-16 rounded-full cursor-pointer"
                        />
                        <p className="mt-2 text-center cursor-pointer">
                          {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={`flex justify-center mt-7`}>
                  <Pagination
                    defaultCurrent={1}
                    total={totalPages * 10}
                    showSizeChanger={false}
                    onChange={pageHandler}
                    current={page}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showPopupVerify && (
        <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-50">
          <div className="my-4 relative sm:w-2/5 w-full py-8 gap-3 px-6 bg-[#F7F7F7] max-h-[90%] overflow-auto rounded-md">
            <FaTimes
              onClick={() => {
                setShowPopupVerify(false);
                setInvitee("");
                setGid("");
                setCountryCode("");
                setPhoneNoo("");
                setEmailValue("");
              }}
              className="absolute top-2 right-2 text-red-600 text-lg cursor-pointer"
            />
            <h1 className="font-semibold text-[1.8rem] mb-4 w-full text-center">
              Verify Invitee
            </h1>
            <div className="w-full">
              <input
                type="text"
                name=""
                value={invitee}
                onChange={(e) => setInvitee(e.target.value)}
                placeholder="Invitee Name"
                className="lg:text-[1.1rem] w-[100%] border border-gray-300 mb-4  bg-white rounded-[0.5rem] p-2"
              />
              <h2 className="mb-2 font-semibold">Verify Via</h2>
              <div className="flex gap-4">
                <div className="mb-2 flex items-center">
                  <input
                    type="radio"
                    id="gidOption"
                    name="contactMethod"
                    value="gid"
                    className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                    checked={contactMethod === "gid"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="gidOption"
                    className="cursor-pointer text-sm inline-flex items-center ml-2"
                  >
                    GID
                  </label>
                </div>
                <div className="mb-2 flex items-center">
                  <input
                    type="radio"
                    id="phoneOption"
                    name="contactMethod"
                    value="phone"
                    className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                    checked={contactMethod === "phone"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="phoneOption"
                    className="cursor-pointer text-sm inline-flex items-center ml-2"
                  >
                    Phone Number
                  </label>
                </div>

                <div className="mb-2 flex items-center">
                  <input
                    type="radio"
                    id="emailOption"
                    name="contactMethod"
                    value="email"
                    className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                    checked={contactMethod === "email"}
                    onChange={handleOptionChange}
                  />
                  <label
                    htmlFor="emailOption"
                    className="cursor-pointer text-sm inline-flex items-center ml-2"
                  >
                    Email
                  </label>
                </div>
              </div>

              {contactMethod === "gid" && (
                <div className="w-full mb-3 mt-2">
                  <input
                    type="text"
                    name=""
                    onChange={(e) => setGid(e.target.value)}
                    placeholder="Enter GID"
                    className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                  />
                </div>
              )}
              {contactMethod === "phone" && (
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="GY"
                  international
                  value={phone}
                  onChange={handleChange(setPhone, "tt")}
                  countryCallingCodeEditable={false}
                  className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
                />
              )}

              {contactMethod === "email" && (
                <div className="w-full mb-3 mt-2">
                  <input
                    type="text"
                    name=""
                    value={emailValue}
                    onChange={(e) => setEmailValue(e.target.value)}
                    placeholder="Enter Email"
                    className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                  />
                </div>
              )}

              <div className="w-full flex items-center mt-8 gap-4 justify-center">
                <button
                  onClick={() => {
                    setShowPopupVerify(false);
                    setInvitee("");
                    setGid("");
                    setCountryCode("");
                    setPhoneNoo("");
                    setEmailValue("");
                  }}
                  className={`text-white w-[40%] bg-red-600 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Cancel
                </button>
                <button
                  onClick={Verify}
                  className={`text-white w-[40%] bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          showDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center font-semibold mt-[1.5rem] mb-5">
            Are you sure you want to delete this event?
          </p>
          <div className="flex w-full items-center justify-center gap-2">
            <button
              onClick={() => setShowDone(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              NO
            </button>
            <button
              onClick={Delete}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showInviteeBatch ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowInviteeBatch(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center font-semibold mt-[1.5rem] mb-5">
            Are you sure you want to Invite Batch?
          </p>
          <div className="flex w-full items-center justify-center gap-2">
            <button
              onClick={() => setShowInviteeBatch(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              NO
            </button>
            <button
              onClick={InviteBatch}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showInviteDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowInvteDone(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center font-semibold mt-[1.5rem] mb-5">{err}</p>
          <div className="flex w-full items-center justify-center gap-2">
            <button
              onClick={() => setShowInvteDone(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              OK
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center font-semibold mt-[1.5rem] mb-5">{err}</p>
          <div className="flex w-full items-center justify-center gap-2">
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
