import React from "react";
import activeDisc from "../../../../imgs/Discipline-c.png";

const cardsData = [
  {
    id: 1,
    icon: activeDisc, // Replace with the appropriate discipline icon
    title: "120",
    subTitle: "Total Disciplinary Forms",
  },
  {
    id: 2,
    icon: activeDisc, // Replace with the appropriate discipline icon
    title: "85",
    subTitle: "Issued Disciplinary Forms",
  },
  {
    id: 3,
    icon: activeDisc, // Replace with the appropriate discipline icon
    title: "25",
    subTitle: "Pending Disciplinary Forms",
  },
  {
    id: 4,
    icon: activeDisc, // Replace with the appropriate discipline icon
    title: "10",
    subTitle: "Escalated Disciplinary Forms",
  },
];


const DevicesCards = () => {
  return (
    <div className="">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:ml-0 place-items-center ">
        {cardsData.map((card) => (
          <div
            key={card.id}
            className="flex w-full flex-row items-center gap-4 border border-border shadow bg-[#f8f8f8] rounded-md px-5 py-8"
          >
            <div className="w-11 h-1w-11">
              <img
                className="w-full h-full object-contain"
                src={card.icon}
                alt=""
              />
            </div>
            <div className="flex flex-col gap-1">
              <h2 className="text-3xl font-bold">{card.title}</h2>
              <p className="text-lg font-normal">{card.subTitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DevicesCards;
