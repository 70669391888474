import React, { useState } from "react";
import { Avatar, Dropdown, Menu, Pagination } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const EmployeTable = ({ columns, data, pageSize = 6, onAction, actions }) => {
  const dash = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const router = useNavigate();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Filter data based on search term
  const filteredData = data.filter((item) =>
    columns.some((column) =>
      item[column.key]
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  // Action menu for each row
  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => onAction("view", id)}>
        View Profile
      </Menu.Item>
      <Menu.Item key="2" onClick={() => onAction("edit", id)}>
        Edit Details
      </Menu.Item>
      <Menu.Item key="3" onClick={() => onAction("remove", id)}>
        Remove
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="overflow-x-auto custom-scrollbar">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  scope="col"
                  className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                >
                  {column.title}
                </th>
              ))}
              <th className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length + 1}
                  className="text-center text-gray-500 text-lg"
                >
                  <p className="p-10">No data found</p>
                </td>
              </tr>
            ) : (
              currentData.map((row) => (
                <tr
                  key={row.id}
                  className="odd:bg-white even:bg-[#f2fcfc] border-b border-[1px] border-[#d9d9d970] hover:bg-gray-100 text-md"
                >
                  {console.log({ row })}
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className="px-4 py-2 text-primary whitespace-nowrap"
                    >
                      {column.render
                        ? column.render(row[column.key], row)
                        : row[column.key]}
                    </td>
                  ))}
                  <td className="px-4 py-2 whitespace-nowrap">
                    <Dropdown overlay={actions(row.id)} trigger={["click"]}>
                      <button className="text-primary bg-white border border-gray-300 px-3 py-1 rounded-md cursor-pointer">
                        Actions
                      </button>
                    </Dropdown>
                    {/* <Dropdown overlay={actionMenu(row.id)} trigger={["click"]}>
                      <button className="text-primary bg-white border border-gray-300 px-3 py-1 rounded-md cursor-pointer">
                        Actions
                      </button>
                    </Dropdown> */}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="font-semibold pl-1 text-gray-600">
          Showing <b className="text-primary">{startIndex + 1}</b> to{" "}
          <b className="text-primary">{endIndex}</b> of{" "}
          <b className="text-primary">{filteredData.length}</b> entries
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredData.length}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default EmployeTable;
