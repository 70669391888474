import across from "../../../../imgs/across.png";
import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Card from "./Card";
import Table from "./inviteeTable";
const Listing = () => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const [test, setTest] = useState(false);
  const user = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const eventId = useSelector((state) => state.eventId);
  const token = useSelector((state) => state.token);
  const subEventId = useSelector((state) => state.subEventId);
  const items = [
    {
      key: "1",
      label: `Total Invitee`,
      count: 90,
    },
    {
      key: "2",
      label: `Joined`,
      count: 46,
    },
    {
      key: "3",
      label: `Leaved`,
      count: 44,
    },
    {
      key: "4",
      label: "Pending",
      count: 44,
    },
  ];

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);

  return (
    <>
      <div
        className={`${
          user === "merchant-owner" ? "mx-16 mt-[1rem]" : "mx-16 mt-[4rem]"
        }`}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={`flex justify-between items-center mt-4`}>
            <div className="top-[2.8] cursor-pointer flex items-center  gap-2 justify-center">
              <img
                onClick={() => {
                  if (user === "merchant-owner") {
                    if (dash === "/Merchant/Event/Invitee") {
                      router("/Merchant/EventDetail");
                    } else {
                      router("/Merchant/SubEventDetail");
                    }
                    return;
                  }
                  dash === "/Event/Invitee"
                    ? router("/EventDetails")
                    : router("/SubEventDetails");
                }}
                className="w-[1.7rem] w-[1.2rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold sm:text-[1.8rem] text-[1.4rem]">
                Joinee Details
              </h2>
            </div>

            {/* <button
              onClick={() => {
                // console.log("Add New Merchant clicked");
              }}
              className={` pointer-events-none
                  text-white bg-primary pl-5 pr-5 pt-2 pb-2 rounded-lg sm:text-lg text-md `}
            >
              Add New Merchant
            </button> */}
          </div>
        </div>
      </div>
      <div
        className={`${
          user === "merchant-owner"
            ? "bg-[#F6F4F4] mx-10 p-4 mb-4 mt-4 rounded"
            : "bg-[#F6F4F4] mx-16 p-4 mb-4 mt-4 rounded"
        }`}
      >
        <div className="flex flex-wrap justify-center sm:ml-0 place-items-center gap-4 mt-[1rem] mb-8">
          {items.map((item) => (
            <Card key={item.key} item={item} />
          ))}
        </div>

        <div className="mt-6">
          <Table />
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{""}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
};

export default Listing;
