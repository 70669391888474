import React, { useState } from "react";
import logo from "../../../imgs/logo12.png";
import activdash from "../../../imgs/dashactive.png";
import Inactivdash from "../../../imgs/dashnactive.png";
import InactiveDisc from "../../../imgs/Discipline-c.png";
import activeDisc from "../../../imgs/Discipline.png";
import InactiveAwards from "../../../imgs/Awards-c.png";
import activeAwards from "../../../imgs/Awards.png";
import InactiveReports from "../../../imgs/Reoprts-c.png";
import activeReoprts from "../../../imgs/Reoprts.png";
import outNactive from "../../../imgs/outstanding.png";
import across from "../../../imgs/across.png";
import inactivelogOff from "../../../imgs/logOff.png";
import empA from "../../../imgs/empA.png";
import payactive from "../../../imgs/paymentactive.png";
import payNactive from "../../../imgs/paymentNactive.png";
import empI from "../../../imgs/empI.png";
import outactive from "../../../imgs/outstandingactive.png";
import activeDevice from "../../../imgs/device-white.png";
import device from "../../../imgs/device-green.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuFoldOutlined } from "@ant-design/icons";
const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar);
  const dataUser = useSelector((state) => state.dataUser);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const router = useNavigate();
  const menuItems = [
    {
      key: "1",
      activeIcon: activdash,
      text: "Dashboard",
      inactiveIcon: Inactivdash,
      router: "/AttendanceDashBoard",
    },
    {
      key: "2",
      activeIcon: empA,
      text: "Employees",
      inactiveIcon: empI,
      router: "/AttendanceEmployees",
    },
    {
      key: "3",
      activeIcon: outactive,
      text: "Attendance",
      inactiveIcon: outNactive,
      router: "/Attendance",
    },
    {
      key: "4",
      activeIcon: activeDevice,
      text: "Devices",
      inactiveIcon: device,
      router: "/Devices",
    },
    {
      key: "5",
      activeIcon: payactive,
      text: "E-Requests",
      inactiveIcon: payNactive,
      router: "/ERequests",
    },
    {
      key: "7",
      activeIcon: activeDisc,
      text: "Discipline",
      inactiveIcon: InactiveDisc,
      router: "/Discipline",
    },
    {
      key: "8",
      activeIcon: activeAwards,
      text: "Awards",
      inactiveIcon: InactiveAwards,
      router: "/Awards",
    },
    {
      key: "9",
      activeIcon: activeReoprts,
      text: "Reports",
      inactiveIcon: InactiveReports,
      router: "/AttendanceReport",
    },
    // {
    //   key: "5",
    //   activeIcon: Activeperson_pin,
    //   text: "Payroll",
    //   router: "",
    //   inactiveIcon: person_pin,
    // },
    {
      key: "6",
      activeIcon: inactivelogOff,
      text: "Log Off",
      inactiveIcon: inactivelogOff,
    },
  ];

  return (
    <div className="bg-white rounded-lg flex space-between flex-col h-full ">
      <aside id="logo-sidebar" aria-label="Sidebar" className="h-full border-r border-gray-200">
        <div className="px-4 py-4">
          {/* <a
            href="#"
            className="flex items-center gap-2 h-[5rem]"
            onClick={() => {
              router("/MHSSS/dashboard");
            }}
          >
            <img src={logo} className="h-[4rem] w-[4rem]" alt="Flowbite Logo" />
            <h1 className="font-bold text-lg text-primary">MHSSS Accounting</h1>
            <MenuFoldOutlined
              className="text-2xl cursor-pointer text-gray-600 sm:hidden"
              onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: false })}
            />
          </a> */}

          <div className="flex justify-between">
            <a
              href="#"
              className="flex items-center mb-4"
              onClick={(event) => {
                dispatch({ type: "SIDEBAR", num: "1" });
                event.preventDefault();
                // router("/MHSSS/dashboard");
              }}>
              <img src={logo} className="h-[4rem] w-[4rem] mr-2" alt="Flowbite Logo" />
              <span className="text-xl font-bold text-primary">Time & Attendance</span>
            </a>
            <MenuFoldOutlined
              className="text-2xl cursor-pointer text-gray-600 sm:hidden"
              onClick={() => dispatch({ type: "OPEN_SIDEBAR", num: false })}
            />
          </div>

          <ul className="space-y-1 font-medium text-primary text-[0.9rem]">
            {menuItems.map((menuItem) => (
              <li
                key={menuItem.key}
                onClick={() => {
                  if (menuItem.key === "6") {
                    if (dataUser?.data?.user?.roles[0] === "superadmin")
                      router("/superAdminDashboard");
                    else if (dataUser?.data?.user?.roles[0] === "admin") router("/adminDashboard");
                    else router("/EDashboard");
                  } else dispatch({ type: "SIDEBAR", num: menuItem.key });
                }}>
                <a
                  href="#"
                  className={`flex items-center   p-3  ${
                    sidebar === menuItem.key
                      ? "bg-primary text-white rounded-lg"
                      : "text-gray-900 border-b hover:bg-gray-100"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    router(menuItem.router);
                  }}>
                  <img
                    src={sidebar === menuItem.key ? menuItem.activeIcon : menuItem.inactiveIcon}
                    alt={menuItem.text}
                    className="h-5 w-5 mr-3"
                  />
                  <span className={sidebar === menuItem.key ? "text-white" : "text-primary"}>
                    {menuItem.text}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </aside>

      {/* Profile Section */}
      <p className="text-gray-400 mt-auto mb-6 text-center px-4 py-2">Version 1.0.0</p>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}>
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div className="absolute top-[-11%] left-[42%]" onClick={() => setShow(false)}>
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{""}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]">
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
