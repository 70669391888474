import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Cell,
} from "recharts";

const timeLossData = [
  { department: "Nursing", july: 2120, august: 1741 },
  { department: "Admin", july: 601, august: 536 },
  { department: "Medical", july: 450, august: 347 },
  { department: "Facilities", july: 287, august: 236 },
  { department: "Supply Chain", july: 176, august: 142 },
];

const absentDaysData = [
  { department: "Nursing", july: 227, august: 168 },
  { department: "Admin", july: 45, august: 38 },
  { department: "Medical", july: 25, august: 25 },
  { department: "Facilities", july: 39, august: 38 },
  { department: "Supply Chain", july: 84, august: 23 },
];
// Lighter color palette based on #1BA397
const colors = [
  "#A7D8D2", // Lighter shade of #1BA397 (Pastel green)
  "#7AC6B4", // Another light green variation
  "#B4E5DF", // Very light mint green
  "#93BFA5", // Lighter, desaturated green
  "#80C4B2", // Soft pastel greenish-blue
];

const ReportsPageAlternate = () => {
  const router = useNavigate();
  return (
    <div className="min-h-screen bg-gray-50 p-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <header className="">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">Report</h1>
          {/* <p className="text-gray-600">July 2024 - August 2024</p> */}
        </header>
        <button
          className="bg-primary text-white px-3 py-2 font-semibold rounded-md"
          onClick={() => {
            router("/AttendanceReport/TableauReport");
          }}
        >
          View Reports
        </button>
      </div>

      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700">
            Total Time Loss
          </h2>
          <p className="text-gray-500">
            Reduction: <span className="text-green-500 font-bold">18%</span>
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700">
            Total Absent Days
          </h2>
          <p className="text-gray-500">
            Reduction: <span className="text-green-500 font-bold">26%</span>
          </p>
        </div>
        {/* Additional Cards */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700">
            Total Hours Worked
          </h2>
          <p className="text-gray-500">
            Increase: <span className="text-blue-500 font-bold">12%</span>
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold text-gray-700">
            Employee Productivity
          </h2>
          <p className="text-gray-500">
            Improvement: <span className="text-orange-500 font-bold">15%</span>
          </p>
        </div>
      </div>

      {/* Chart Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Bar Chart */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Time Loss Comparison
          </h3>
          <div className="overflow-x-auto">
            <BarChart width={400} height={300} data={timeLossData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="department" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="july" fill="#8884d8" />
              <Bar dataKey="august" fill="#1BA397" />
            </BarChart>
          </div>
        </div>

        {/* Pie Chart */}
        {/* <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Absent Days Distribution
          </h3>
          <div className="overflow-x-auto">
            <PieChart width={400} height={300}>
              <Pie
                data={absentDaysData}
                dataKey="august"
                nameKey="department"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#1BA397"
                label
              />
              <Tooltip />
            </PieChart>
          </div>
        </div> */}

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Absent Days Distribution
          </h3>
          <div className="overflow-x-auto">
            <PieChart width={400} height={300}>
              <Pie
                data={absentDaysData}
                dataKey="august"
                nameKey="department"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#1BA397" // Default color in case the dynamic color is not applied
                label
              >
                {/* Loop through the data to apply different colors */}
                {absentDaysData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      </div>

      {/* Line Chart */}
      <div className="bg-white p-6 rounded-lg shadow-md my-8">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">
          Time Loss Trend
        </h3>
        <div className="overflow-x-auto flex items-center justify-center">
          <LineChart width={800} height={300} data={timeLossData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="department" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="july" stroke="#8884d8" />
            <Line type="monotone" dataKey="august" stroke="#1BA397" />
          </LineChart>
        </div>
      </div>

      {/* Data Table */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">
          Detailed Data
        </h3>
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 border border-gray-300 text-left">
                  Department
                </th>
                <th className="px-4 py-2 border border-gray-300 text-right">
                  Time Loss 2024
                </th>
                <th className="px-4 py-2 border border-gray-300 text-right">
                  Tardiness Time Loss
                </th>
                <th className="px-4 py-2 border border-gray-300 text-right">
                  Total Absents
                </th>
                <th className="px-4 py-2 border border-gray-300 text-right">
                  Total Time Loss
                </th>
              </tr>
            </thead>
            <tbody>
              {timeLossData.map((item, index) => (
                <tr key={index} className="even:bg-gray-50">
                  <td className="px-4 py-2 border border-gray-300">
                    {item.department}
                  </td>
                  <td className="px-4 py-2 border border-gray-300 text-right">
                    {item.july}
                  </td>
                  <td className="px-4 py-2 border border-gray-300 text-right">
                    {item.august}
                  </td>
                  <td className="px-4 py-2 border border-gray-300 text-right">
                    {absentDaysData[index].july}
                  </td>
                  <td className="px-4 py-2 border border-gray-300 text-right">
                    {absentDaysData[index].august}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReportsPageAlternate;
