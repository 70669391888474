import axios from "axios";
import { Select } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const EmployeeInfo = ({
  setShow,
  setShowErrorModal,
  setErr,
  setRefetch,
  refetch,
}) => {
  const { Option } = Select;
  const data = useSelector((state) => state.Data);
  const edit = useSelector((state) => state.Edit);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const router = useNavigate();
  const [selectedOption, setSelectedOption] = useState("Distance");
  const [method, setMethod] = useState("");
  const [typeUnit, setTypeUnit] = useState(null);
  const [typeValue, setTypeValue] = useState("");
  const [fee, setFee] = useState("");
  const [location, setLocation] = useState(null);
  const [department, setDepartment] = useState([]);
  const [roles, setRoles] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    if (edit) {
      setLocation(data?.mhsssfacilityId);
      setSelectedRole(data?.employeeRole);
      setFee(data?.limit);
    }
  }, []);

  const nextHandler = () => {
    if (location === null) {
      setErr("Please select department");
      setShowErrorModal(true);
      return;
    }
    if (selectedRole === null) {
      setErr("Please select role");
      setShowErrorModal(true);
      return;
    }

    if (fee === "") {
      setErr("Please enter limit");
      setShowErrorModal(true);
      return;
    }
    setShow(false);
    var body;
    body = {
      employeeRole: selectedRole,
      limit: fee,
      mhsssfacilityId: location,
    };

    const api = edit
      ? `v1/inv-purchase-limit/${data?.id}`
      : `v1/inv-purchase-limit`;
    const method = edit ? "put" : "post"; 

    axios({
      method, 
      url: `${process.env.REACT_APP_BASE_URL}/${api}`,
      data: body, 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setShow(false);
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErrorModal(true);
      });
  };

  //Get subroles of employee
  useEffect(() => {
    const api =
      user === "admin"
        ? "v1/privileges/list-roles-privileges/by-admin"
        : "v1/privileges/list-roles-privileges/by-superadmin";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        const rolesWithLabelValue = data?.data?.roles
          ?.map((role) => ({
            label: role.charAt(0).toUpperCase() + role.slice(1),
            value: role,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        // const rolesWithLabelValue = data?.data?.roles?.map(role => ({ label: role, value: role }));
        setRoles(rolesWithLabelValue);
      })
      .catch(() => {});
  }, []);

  //Get deparmtnet locations and warehouse
  useEffect(() => {
    const api = "v1/mhsss-facility";
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        const filteredData = data?.data?.rows.filter(
          (item) => item.type === "department"
        );

        setDepartment(filteredData);
      })

      .catch(() => {});
  }, []);

  const options = department?.map((item) => ({
    value: item?.id,
    label: item?.name,
    address: [
      item?.lot,
      item?.address,
      item?.regionNo,
      item?.regionName,
      item?.city,
      item?.ward,
    ]
      .filter(Boolean)
      .join(", "),
  }));

  const renderOption = (option) => (
    <Option key={option?.value} value={option?.value}>
      <div className="flex flex-col">
        <h1 className="font-semibold text-gray-700 text-[0.9rem]">
          {option?.label}
        </h1>
        <p className="text-sm text-gray-500">{option?.address}</p>
      </div>
    </Option>
  );

  return (
    <>
      <div
        className={`w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-30 `}
      >
        <div className="relative p-4 w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between p-4 md:p-4 border-b rounded-t ">
              <h3 className="text-lg font-semibold text-gray-900 ">
                {edit ? "Update" : "Add New"} Purchase Limit
              </h3>
              <button
                onClick={() => {
                  setShow(false);
                }}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center  "
                data-modal-toggle="select-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <div className="flex justify-center  overflow-y-auto custom-scrollbar min-h-fit">
              <div className="w-[90%] mt-3  ">
                <div>
                  {/* Delivery Type */}

                  {/* Delivery Charges Bases on */}
                  <div className="w-full  mt-2">
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Select Department :
                    </h2>
                    <Select
                      placeholder="Select Department"
                      // className="wow w-[100%] mb-4"
                      className="w-[100%] woww "
                      renderOption={renderOption}
                      onChange={(value) => {
                        setLocation(value);
                      }}
                      value={location !== "" ? location : null}
                    >
                      {options?.map(renderOption)}
                    </Select>
                  </div>
                  <div className="w-full  mt-2">
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Select Role :
                    </h2>
                    <Select
                      placeholder="Select Role"
                      optionFilterProp="children"
                      className="wow "
                      onChange={(val) => setSelectedRole(val)}
                      size="middle"
                      options={roles}
                      value={selectedRole}
                    />
                  </div>

                  <div className="mt-2">
                    <h2 className="text-gray-600 text-[0.9rem] font-semibold mb-2">
                      Purchase Limit :
                    </h2>

                    <input
                      type="text"
                      placeholder="Purchase Limit"
                      value={fee}
                      onChange={(e) => {
                        setFee(e.target.value);
                      }}
                      className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 "
                    />
                  </div>
                </div>
                <div className="flex justify-center pb-6 mt-4">
                  <button
                    onClick={() => {
                      setShow(false);
                    }}
                    className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[45%]"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      nextHandler();
                    }}
                    className="ml-5 cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[45%]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeInfo;
