import { useState } from "react";
import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Main from "./main";
import Nav from "../vendor/navbar";
import AddTax from "./add";
import ErrorModal from "../../Merchant/Modals/errorModal";
import SuccessModal from "../../modals/successModal";

const Mid = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const [showAdd, setShowAdd] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [err, setErr] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);
  const [test, setTest] = useState(false);
  return (
    <>
      <div className="bg-[#D9EFED] h-full">
        <Nav />
        <div
          className={`${
            user === "vendor-owner"
              ? "flex items-center justify-center bg-[#D9EFED]"
              : "flex items-center justify-center"
          }`}
        >
          <div className="md:w-[95%] mt-[1rem] rounded-md w-[95%] sm:px-20 px-4">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => router("/SuperAdmin/InventoryDashboard")}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Taxes
              </h2>
            </div>
            <div className="flex justify-end mt-4 gap-3">
              <button
                type="button"
                onClick={() => {
                  setShowAdd(true);
                }}
                className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[13rem]"
              >
                Add New Tax
              </button>
            </div>
            {/* <h4 className="text-gray-700 mb-2 font-medium sm:mt-0 mt-5">
              Credit Duration-Days for payment completion after delivery
            </h4> */}

            <Main
              setRefetch={setRefetch}
              refetch={refetch}
              setShowSuccessModal={setShowSuccessModal}
              setErr={setErr}
              setShowErrorModal={setShowErrorModal}
            />
          </div>
        </div>
      </div>

      {showErrorModal && (
        <ErrorModal
          show={showErrorModal}
          setShow={setShowErrorModal}
          err={err}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          setShow={setShowSuccessModal}
          message={err}
          route={"/TaxSetting"}
        />
      )}
      {showAdd && (
        <AddTax
          setShow={setShowAdd}
          setErr={setErr}
          setRefetch={setRefetch}
          refetch={refetch}
          setShowErrorModal={setShowErrorModal}
        />
      )}
    </>
  );
};

export default Mid;
