import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../../../Merchant/Modals/errorModal";
import SuccessModal from "../../../modals/successModal";
import axios from "axios";
import { Pagination, Select } from "antd";
import deleteimg from "../../../../imgs/delete.svg";

const SelectProgram = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [data, setData] = useState([]);
  const [type, setType] = useState(null);
  const [afterApi, setAfterApi] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [addChargesModal, setAddChargesModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [search, setSearch] = useState("");

  const pageHandler = (e) => {
    setPage(e);
  };

  //Get Listing
  useEffect(() => {
    dispatch({ type: "EDIT", num: false });
    dispatch({ type: "DATA", num: null });

    setAfterApi(false);
    const delay = 1000;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: 6,
        skip: 0,
        page: page,
        sortBy: "createdAt:desc",
        buyerType: "mhsss-global",
        // ...(search && { search }),
      };

      fetchAllAccess(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [search, page, refetch]);

  const fetchAllAccess = (params, cancelTokenSource) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-buyer-tax-doc/list-for-vendor`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        }
      )
      .then((res) => {
        setData(res?.data?.rows);
        setTotalPages(res?.data?.totalPages);
        setAfterApi(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeletePrice = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/inv-delivery-fee/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefetch(!refetch);
        setErr(`Delivery Charges Deleted Successfully`);
        setShow(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };
  return (
    <div className="mt-8">
      {/* <div
        className={`flex justify-end items-center gap-2 cursor-pointer w-full p-3
        }`}
      >
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            // id="default-search"
            className="block w-[16rem] p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-md    "
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <button
          className="bg-primary text-white rounded-md px-8 py-2"
          onClick={() => {
            setAddChargesModal(true);
          }}
        >
          Add Charges
        </button>
      </div> */}
      <div className="flex justify-center">
        <div className={`bg-[#fafaf9] shadow-lg rounded-sm  p-6  w-full mx-3`}>
          {data?.length === 0 && afterApi ? (
            <div className="flex justify-center items-center p-16">
              <h1 className="text-lg text-gray-600">No Data Found</h1>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-3 gap-3">
                {data?.map((item, index) => (
                  <div key={index}>
                    <div className="bg-white rounded-lg p-5 flex justify-between items-center shadow-sm  mt-2">
                      <div>
                        <h2 className="text-[1rem] font-semibold text-gray-900 capitalize">
                          {item?.businessType}{" "}
                        </h2>
                        <p
                          className={`${
                            item?.status !== "pending"
                              ? "text-primary font-medium"
                              : "text-gray-500 font-medium"
                          } capitalize text-xs`}
                        >
                          {item?.status === "pending"
                            ? "Requested"
                            : item?.status}
                        </p>
                      </div>
                      <div
                        className="text-primary flex items-center gap-2 cursor-pointer font-semibold text-[0.9rem]"
                        onClick={() => {
                          dispatch({ type: "EDIT", num: true });
                          dispatch({ type: "DATA", num: item });
                          router("/TaxDetail");
                        }}
                      >
                        View Detail
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex justify-center mt-7">
                <Pagination
                  defaultCurrent={1}
                  total={totalPages * 10}
                  showSizeChanger={false}
                  onChange={pageHandler}
                  current={page}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {show && (
        <SuccessModal
          show={show}
          setShow={setShow}
          message={err}
          //   route="/scheduleVerification/selectedEmployees"
        />
      )}
      {/* {addChargesModal && (
        <AddCharges
          setShow={setAddChargesModal}
          setErr={setErr}
          setRefetch={setRefetch}
          refetch={refetch}
          setShowErrorModal={setShowErr}
        />
      )} */}
    </div>
  );
};

export default SelectProgram;
