import { useSelector } from "react-redux";
import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
const PowerBiPage = () => {
  const router = useNavigate();
  const user = localStorage.getItem("role");
  const ref = useRef(null);

  useEffect(() => {
    const vizElement = ref.current.getElementsByTagName("object")[0];
    vizElement.style.width = "100%";
    if (ref.current.offsetWidth > 800) {
      vizElement.style.height = "1327px";
    } else if (ref.current.offsetWidth > 500) {
      vizElement.style.height = "1327px";
    } else {
      vizElement.style.height = "2477px";
    }

    const scriptElement = document.createElement("script");
    scriptElement.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
    vizElement.parentNode.insertBefore(scriptElement, vizElement);
  }, []);

  return (
    <div>
      {/* <Nav /> */}
      <div className="relative flex justify-start pl-[1rem] pr-[1rem] items-center text-[1.8rem] font-bold mb-[1rem]">
        <div
          onClick={() => {
            user === "admin"
              ? router("/AdminDashboard")
              : user === "superadmin"
              ? router("/AttendanceReport")
              : router("/EDashboard");
          }}
          className="cursor-pointer pt-[2rem] mr-5"
        >
          <img className="w-[1.7rem]" src={back5} alt="" />
        </div>
        <div className="flex pt-[2rem]">
          <h2>Reports</h2>
        </div>
      </div>

      <div
        ref={ref}
        className="tableauPlaceholder w-full h-auto relative"
        id="viz1733760632885"
        style={{
          position: "relative",
        }}
      >
        <noscript>
          <a href="#">
            <img
              alt="Dashboard"
              src="https://public.tableau.com/static/images/GP/GPHCTimeAndAttendenceAnalysisReport_v1/Dashboard/1_rss.png"
              style={{
                border: "none",
              }}
            />
          </a>
        </noscript>
        <object
          className="tableauViz"
          style={{
            display: "none",
          }}
        >
          <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
          <param name="embed_code_version" value="3" />
          <param name="site_root" value="" />
          <param
            name="name"
            value="GPHCTimeAndAttendenceAnalysisReport_v1/Dashboard"
          />
          <param name="tabs" value="no" />
          <param name="toolbar" value="yes" />
          <param
            name="static_image"
            value="https://public.tableau.com/static/images/GP/GPHCTimeAndAttendenceAnalysisReport_v1/Dashboard/1.png"
          />
          <param name="animate_transition" value="yes" />
          <param name="display_static_image" value="yes" />
          <param name="display_spinner" value="yes" />
          <param name="display_overlay" value="yes" />
          <param name="display_count" value="yes" />
          <param name="language" value="en-US" />
        </object>
      </div>

      {/* <div
        ref={ref}
        className="tableauPlaceholder w-full h-auto relative"
        id="viz1733250898453"
        style={{ position: "relative" }}
      >
        <noscript>
          <a href="https://public.tableau.com/views/GPHCTimeAndAttendenceAnalysisReport/Dashboard?:language=en-US&publish=yes&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link">
            <img
              alt="Dashboard"
              src="https://public.tableau.com/static/images/GP/GPHCTimeAndAttendenceAnalysisReport/Dashboard/1_rss.png"
              style={{ border: "none" }}
            />
          </a>
        </noscript>
        <object className="tableauViz" style={{ display: "none" }}>
          <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
          <param name="embed_code_version" value="3" />
          <param name="site_root" value="" />
          <param
            name="name"
            value="GPHCTimeAndAttendenceAnalysisReport/Dashboard"
          />
          <param name="tabs" value="no" />
          <param name="toolbar" value="yes" />
          <param
            name="static_image"
            value="https://public.tableau.com/static/images/GP/GPHCTimeAndAttendenceAnalysisReport/Dashboard/1.png"
          />
          <param name="animate_transition" value="yes" />
          <param name="display_static_image" value="yes" />
          <param name="display_spinner" value="yes" />
          <param name="display_overlay" value="yes" />
          <param name="display_count" value="yes" />
          <param name="language" value="en-US" />
          <param
            name="url"
            value="https://public.tableau.com/views/GPHCTimeAndAttendenceAnalysisReport/Dashboard?:language=en-US&publish=yes&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link"
          />
        </object>
      </div> */}
    </div>
  );
};

export default PowerBiPage;
