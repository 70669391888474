import React, { useEffect } from "react";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";
import { useDispatch } from "react-redux";
import DisciplineMain from "./DisciplineMain";

const Devices = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "7" });
  }, []);

  return (
    <AttendanceDashboardWrapper>
      <DisciplineMain />
    </AttendanceDashboardWrapper>
  );
};

export default Devices;
