import React, { useEffect, useState } from "react";
// import { Button, Carousel } from "antd";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import axios from "axios";
import saved from "../../../imgs/saved.png";
import loc from "../../../imgs/locationn.png";
// import moment from "moment";
const EventCard = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const [events, setEvents] = useState([]);
  const [type, setType] = useState(null);
  const [fetch, setFetch] = useState(false);
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const user = useSelector((state) => state.user);
  const userId = dataUser?.data?.user
    ? dataUser?.data?.user.id
    : dataUser?.data?.merchuser?.id;

    const dispatch = useDispatch();
  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      const params = {
        limit: page * 6,
        sortBy: "createdAt:asc",
        // ...(search && { search }),
        ...(type && { type }),
      };
      fetchFavEvents(params, newCancelTokenSource);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled by the user.");
      }
    };
  }, [page, type, fetch, search]);
  const fetchFavEvents = (params, cancelTokenSource) => {
    const api =
      user === "merchant-owner"
        ? `v1/favorite-event/merch-event`
        : `v1/favorite-event/event`;
    let url = `${process.env.REACT_APP_BASE_URL}/${api}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
        cancelToken: cancelTokenSource.token,
      })
      .then((data) => {
        setEvents(data?.data?.rows);
        setTotalPages(data?.data?.totalPages || 1);
      })
      .catch(() => {});
  };

  const handleShowMore = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleShowLess = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };
  const options = [
    // { value: null, label: "All" },
    { value: "public", label: "Public" },
    { value: "private", label: "Private" },
  ];
  // const addFav = (id) => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/v1/favorite-event/event`,
  //       {
  //         eventId: id,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       setFetch(!fetch);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const removeFav = (id) => {
    const url =
      user === "merchant-owner"
        ? `v1/favorite-event/merch-event/${id}`
        : `v1/favorite-event/event/${id}`;
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setFetch(!fetch);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Join = (id, type) => {
    let url;
    let method;
    // if (type === "private") {
    //   url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-private-event`;
    //   method = "put";
    // } else if (type === "public") {
    //   url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-public-event`;
    //   method = "post";
    // }
    if (type === "private") {
      url =
        user === "merchant-owner"
          ? `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-merch-private-event`
          : `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-private-event`;
      method = "put";
    } else if (type === "public") {
      url =
        user === "merchant-owner"
          ? `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-merch-public-event`
          : `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-public-event`;
      method = "post";
    }
    axios[method](
      url,
      {
        eventId: id,
        status: "joined",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(() => {
        setFetch(!fetch);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const checkInviteeStatus = (event) => {
  //   const userId = dataUser?.data?.user?.id;
  //   if (userId && event?.eventinvitees?.length > 0) {
  //     console.log("Testing Val", userId, event?.eventinvitees);
  //     const invitee = event.eventinvitees.find(
  //       (invitee) => invitee.inviteeUserId === userId
  //     );
  //     if (invitee) {
  //       return invitee.status;
  //     } else {
  //       return "no";
  //     }
  //   } else {
  //     return "no";
  //   }
  // };
  const isEventOver = (start) => {
    console.log("Start", start);
    const currentTime = new Date();
    const eventStartTime = new Date(start);
    console.log(
      "Test",
      currentTime,
      eventStartTime,
      eventStartTime < currentTime
    );
    return eventStartTime < currentTime;
  };
  return (
    <div className="flex w-full pt-[4rem] pb-[8rem] flex-col justify-center">
      <div className="w-full flex items-center mb-6 gap-2 justify-between">
        <div className="relative sm:w-[40%] w-full flex gap-2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mb-4 text-gray-500 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="simple-search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="bg-gray-50 mb-4 py-[10px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
            placeholder="Search Event..."
            required=""
          />
          <div className="w-[10rem]">
            <Select
              placeholder="Filter By"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              value={type}
              onChange={(value) => setType(value)}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: "gray",
              }}
              className="wow mb-4 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full"
              options={options}
            />
          </div>
        </div>
      </div>
      {events.length === 0 && (
        <div className="text-center w-full flex items-center justify-center text-gray-500 text-lg">
          <p className="p-10">No Saved Events</p>
        </div>
      )}
      <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-5 gap-y-8">
        {events.length > 0 &&
          events.map((event) => (
            <div key={event.event?.id} className="w-full relative">
              <img
                className="w-full h-48 rounded-tr-[1.5rem] rounded-tl-[1.5rem] border border-b-none"
                src={event?.event?.coverImage}
                alt={event.event?.name}
              />
              <div className="px-4 py-4 bg-gray-100 flex flex-col gap-2 rounded-br-[1.5rem] rounded-bl-[1.5rem] relative">
                <div
                  // onClick={() => router("/EventDetails")}
                  onClick={() => {
                    // if (
                    //   dash === "/PensionerDashBoard/Subevents" ||
                    //   dash === "/EmployeeDashBoard/Subevents" ||
                    //   dash === "/MerchantDashBoard/Subevents"
                    // ) {
                    //   dispatch({ type: "SUB-EVENT_ID", num: event.id });
                    //   if (user === "merchant-owner") {
                    //     router("/Merchant/SubEventDetail");
                    //   } else {
                    //     router("/SubEventDetails");
                    //   }
                    // } else {
                    //   dispatch({ type: "EVENT_ID", num: event.id });

                    //   if (event?.hostUser) {
                    //     dispatch({
                    //       type: "EVENT_CREATOR",
                    //       num: event?.hostUser,
                    //     });
                    //   } else {
                    //     dispatch({
                    //       type: "EVENT_CREATOR",
                    //       num: event?.hostMerchUser,
                    //     });
                    //   }

                      if (user === "merchant-owner") {
                        router("/Merchant/EventDetail");
                        return;
                      }
                      router("/EventDetails");
                    // }
                  }}
                  className="font-semibold text-xl mb-1 hover:text-[#1BA397] cursor-pointer"
                >
                  {event.event?.name}
                </div>
                <div className="flex w-full items-center gap-1">
                  <img src={loc} alt="" className="w-4 h-4 bg-gray-100" />
                  <p className="text-gray-700 text-base  truncate">
                    {event.event?.address}
                  </p>
                </div>
                <div className="w-full flex items-center mt-2 justify-between">
                  {userId ===
                  (event?.event?.hostUserId
                    ? event?.event?.hostUserId
                    : event?.event?.hostMerchUserId) ? (
                    <p className="text-primary font-bold cursor-pointer hover:underline">
                      Created by you
                    </p>
                  ) : event?.event?.allowedPerson ==
                      `${event?.eventJoineeCount}` &&
                    event?.status !== "joined" ? (
                    <p className="text-red-600 font-bold cursor-pointer hover:underline">
                      Slots Filled
                    </p>
                  ) : isEventOver(event?.event?.startDateTime) ? (
                    <p className="text-red-600 font-bold">Joining Closed</p>
                  ) : event?.status !== "joined" ? (
                    <p
                      onClick={() => Join(event?.event?.id, event?.event?.type)}
                      className="text-primary font-bold cursor-pointer hover:underline"
                    >
                      Join now
                    </p>
                  ) : (
                    <p className="text-primary font-bold cursor-pointer hover:underline">
                      Joined
                    </p>
                  )}
                  <p
                    className={`${
                      event?.event?.type === "private"
                        ? "bg-[#6F42C1]"
                        : "bg-[#3395FE]"
                    } font-semibold px-5 py-1 rounded-full text-white capitalize`}
                  >
                    {event?.event?.type}
                  </p>
                </div>
                <div
                  onClick={() => {
                    removeFav(event?.id);
                  }}
                  className={`absolute w-[2rem] h-[2rem] rounded-full bg-primary cursor-pointer text-2xl font-bold flex items-center justify-center text-white -top-[1rem] right-4 ${
                    dash === "/EmployeeDashBoard/Subevents" ||
                    dash === "/PensionerDashBoard/Subevents"
                      ? "hidden"
                      : ""
                  }`}
                >
                  <img src={saved} alt="" className="w-4 h-4" />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="w-full text-center mt-10 flex gap-4">
        {page < totalPages && (
          <p
            className="text-primary hover:underline cursor-pointer w-full text-semibold font-semibold text-lg"
            onClick={handleShowMore}
          >
            Show More
          </p>
        )}
        {page > 1 && (
          <p
            className="text-red-600 hover:underline cursor-pointer w-full text-semibold font-semibold text-lg"
            onClick={handleShowLess}
          >
            Show Less
          </p>
        )}
      </div>
    </div>
  );
};

export default EventCard;
