// import React, { useRef, useState } from "react";
// import Draggable from "react-draggable"; // Install via `npm install react-draggable`
// import certificateImage from "../../../../imgs/certificate.jpg";
// import SignatureCanvas from "react-signature-canvas";
// import moment from "moment";

// const Certificate = ({ setShow }) => {
//   const [name, setName] = useState("");
//   const [date, setDate] = useState("");
//   const signaturePad = useRef(null);
//   const [signatureURL, setSignatureURL] = useState("");

//   const clearSignature = () => {
//     signaturePad.current.clear();
//     setSignatureURL("");
//   };

//   const saveSignature = () => {
//     setSignatureURL(signaturePad.current.toDataURL());
//   };

//   return (
//     <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//       <div className="relative w-full max-w-4xl max-h-[94%] bg-white rounded-lg shadow dark:bg-gray-700 overflow-hidden">
//         {/* Modal Header */}
//         <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
//           <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
//             Add Certificate
//           </h3>
//           <button
//             type="button"
//             className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
//             onClick={() => setShow(false)}
//           >
//             <svg
//               className="w-3 h-3"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 14 14"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
//               />
//             </svg>
//             <span className="sr-only">Close modal</span>
//           </button>
//         </div>

//         <div className="flex w-full">
//           {/* Left side (Input fields and signature upload) */}
//           <div className="w-2/5 p-6 flex flex-col space-y-6">
//             <div>
//               <label
//                 htmlFor="name"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Name
//               </label>
//               <input
//                 id="name"
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 className="mt-1 p-2 w-full border border-gray-300 rounded-md"
//                 placeholder="Enter name"
//               />
//             </div>
//             <div>
//               <label className="block text-sm font-medium text-gray-700">
//                 Title
//               </label>
//               <input
//                 id="name"
//                 type="text"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 className="mt-1 p-2 w-full border border-gray-300 rounded-md"
//                 placeholder="Enter title"
//               />
//             </div>
//             <div>
//               <label
//                 htmlFor="date"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Date
//               </label>
//               <input
//                 id="date"
//                 type="date"
//                 value={date}
//                 onChange={(e) => setDate(e.target.value)}
//                 className="mt-1 p-2 w-full border border-gray-300 rounded-md"
//               />
//             </div>

//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-2">
//                 Signature By
//               </label>
//               <SignatureCanvas
//                 ref={signaturePad}
//                 penColor="black"
//                 canvasProps={{
//                   width: 320,
//                   height: 120,
//                   className: "border border-gray-300 rounded-lg",
//                 }}
//               />
//               <div className="mt-2 text-center">
//                 <button
//                   type="button"
//                   onClick={saveSignature}
//                   className="text-sm text-blue-500 mr-4"
//                 >
//                   Save Signature
//                 </button>
//                 <button
//                   type="button"
//                   onClick={clearSignature}
//                   className="text-sm text-red-500"
//                 >
//                   Clear Signature
//                 </button>
//               </div>
//             </div>
//             <div className="mb-4">
//               <label className="block text-sm font-medium mb-2">
//                 Signature By
//               </label>
//               <SignatureCanvas
//                 ref={signaturePad}
//                 penColor="black"
//                 canvasProps={{
//                   width: 320,
//                   height: 120,
//                   className: "border border-gray-300 rounded-lg",
//                 }}
//               />
//               <div className="mt-2 text-center">
//                 <button
//                   type="button"
//                   onClick={saveSignature}
//                   className="text-sm text-blue-500 mr-4"
//                 >
//                   Save Signature
//                 </button>
//                 <button
//                   type="button"
//                   onClick={clearSignature}
//                   className="text-sm text-red-500"
//                 >
//                   Clear Signature
//                 </button>
//               </div>
//             </div>
//           </div>

//           {/* Right side (Certificate display with draggable overlays) */}
//           <div className="w-3/5 p-2 flex  relative">
//             {/* Certificate image */}
//             <img
//               src={certificateImage}
//               alt="Certificate"
//               className="object-contain max-w-full max-h-full border-4 border-gray-200 rounded-md"
//             />

//             {/* Draggable Overlays */}
//             <Draggable>
//               <div
//                 className="absolute text-lg font-bold text-black cursor-move font-sans"
//                 style={{ top: "50%", left: "35%" }}
//               >
//                 {name}
//               </div>
//             </Draggable>
//             <Draggable>
//               <div
//                 className="absolute text-md text-black cursor-move font-medium"
//                 style={{ top: "70%", left: "23%" }}
//               >
//                 {date ? moment(date).format("MM/DD/YYYY") : ""}
//               </div>
//             </Draggable>
//             {signatureURL && (
//               <Draggable>
//                 <img
//                   src={signatureURL}
//                   alt="Signature"
//                   className="absolute w-[150px] h-[50px] object-contain cursor-move"
//                   style={{ top: "67%", left: "60%" }}
//                 />
//               </Draggable>
//             )}
//           </div>
//         </div>

//         {/* Modal Footer */}
//         <div className="flex items-center justify-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
//           <button
//             type="button"
//             onClick={() => setShow(false)}
//             className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
//           >
//             Save Certificate
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Certificate;

import React, { useRef, useState } from "react";
import Draggable from "react-draggable"; // Install via `npm install react-draggable`
import certificateImage from "../../../../imgs/certificate.jpg";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";
import { Select } from "antd";

const Certificate = ({ setShow }) => {
  const [name, setName] = useState(null);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const managerSignaturePad = useRef(null);
  const directorSignaturePad = useRef(null);
  const [managerSignatureURL, setManagerSignatureURL] = useState("");
  const [directorSignatureURL, setDirectorSignatureURL] = useState("");

  const clearManagerSignature = () => {
    managerSignaturePad.current.clear();
    setManagerSignatureURL("");
  };

  const saveManagerSignature = () => {
    setManagerSignatureURL(managerSignaturePad.current.toDataURL());
  };

  const clearDirectorSignature = () => {
    directorSignaturePad.current.clear();
    setDirectorSignatureURL("");
  };

  const saveDirectorSignature = () => {
    setDirectorSignatureURL(directorSignaturePad.current.toDataURL());
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-4xl max-h-[94%] bg-white rounded-lg shadow dark:bg-gray-700 overflow-hidden">
        {/* Modal Header */}
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Issue Certificate
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={() => setShow(false)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        <div className="flex w-full h-[94%]">
          {/* Left side (Input fields and signature upload) */}
          <div className="w-2/5 p-6 flex flex-col space-y-6 overflow-y-auto  custom-scrollbar overflow-x-hidden h-96">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Employee
              </label>
              <Select
                style={{ marginTop: "7px" }}
                value={name}
                onChange={(value) => setName(value)}
                showSearch
                placeholder="Select Employee"
                optionFilterProp="label"
                className="wow"
                // onSearch={onSearch}
                options={[
                  {
                    value: "Jack Smith",
                    label: "Jack Smith",
                  },
                  {
                    value: "Lucy Gray",
                    label: "Lucy Gray",
                  },
                  {
                    value: "Tom Johnson",
                    label: "Tom Johnson",
                  },
                  {
                    value: "Root Miller",
                    label: "Root Miller",
                  },
                  {
                    value: "William Carter",
                    label: "William Carter",
                  },
                  {
                    value: "Adam Khans",
                    label: "Adam Khans",
                  },
                ]}
              />
              {/* <input
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                placeholder="Enter name"
              /> */}
            </div>
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <input
                id="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                placeholder="Enter title"
              />
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                Date
              </label>
              <input
                id="date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              />
            </div>

            {/* Manager Signature */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                Signature{" "}
                <span className="text-red-600 font-medium">
                  ( Tom Jones , Manager )
                </span>
              </label>
              <SignatureCanvas
                ref={managerSignaturePad}
                penColor="black"
                canvasProps={{
                  width: 310,
                  height: 120,
                  className: "border border-gray-300 rounded-lg",
                }}
              />
              <div className="mt-2 text-center">
                <button
                  type="button"
                  onClick={saveManagerSignature}
                  className="text-sm text-blue-500 mr-4"
                >
                  Save Signature
                </button>
                <button
                  type="button"
                  onClick={clearManagerSignature}
                  className="text-sm text-red-500"
                >
                  Clear Signature
                </button>
              </div>
            </div>

            {/* Director Signature */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                Signature{" "}
                <span className="text-red-600 font-medium">
                  ( R. C. Chung - Director of Human Resources )
                </span>
              </label>
              <SignatureCanvas
                ref={directorSignaturePad}
                penColor="black"
                canvasProps={{
                  width: 310,
                  height: 120,
                  className: "border border-gray-300 rounded-lg",
                }}
              />
              <div className="mt-2 text-center">
                <button
                  type="button"
                  onClick={saveDirectorSignature}
                  className="text-sm text-blue-500 mr-4"
                >
                  Save Signature
                </button>
                <button
                  type="button"
                  onClick={clearDirectorSignature}
                  className="text-sm text-red-500"
                >
                  Clear Signature
                </button>
              </div>
            </div>
          </div>

          {/* Right side (Certificate display with draggable overlays) */}
          <div className="w-3/5 p-2 flex relative bg-gray-50">
            {/* Certificate image */}
            <img
              src={certificateImage}
              alt="Certificate"
              className="object-contain max-w-full max-h-full border-4 border-gray-200 rounded-md"
            />

            {/* Draggable Overlays */}
            <Draggable>
              <div
                className="absolute text-lg font-semibold text-red-600 cursor-move font-serif"
                style={{ top: "50%", left: "35%" }}
              >
                {name}
              </div>
            </Draggable>
            <Draggable>
              <div
                className="absolute text-xs text-red-600 cursor-move font-semibold font-serif"
                style={{ top: "59.4%", left: "68%" }}
              >
                {title}
              </div>
            </Draggable>
            <Draggable>
              <div
                className="absolute text-xs text-green-700 cursor-move font-semibold font-serif"
                style={{ top: "36%", left: "37%" }}
              >
                {date ? moment(date).format("MMMM DD, YYYY") : ""}
              </div>
            </Draggable>
            {managerSignatureURL && (
              <Draggable>
                <img
                  src={managerSignatureURL}
                  alt="Manager Signature"
                  className="absolute w-[150px] h-[50px] object-contain cursor-move"
                  style={{ top: "65%", left: "23%" }}
                />
              </Draggable>
            )}
            {directorSignatureURL && (
              <Draggable>
                <img
                  src={directorSignatureURL}
                  alt="Director Signature"
                  className="absolute w-[150px] h-[50px] object-contain cursor-move"
                  style={{ top: "68%", left: "60%" }}
                />
              </Draggable>
            )}
            <div
              className="absolute text-[9px] text-green-700 cursor-move font-semibold font-serif"
              style={{ top: "78%", left: "23%" }}
            >
              <span className="text-red-600 font-medium">
                {" "}
                Tom Jones , Manager
              </span>
            </div>
            <div
              className="absolute text-[9px] text-green-700 cursor-move font-semibold font-serif"
              style={{ top: "78%", left: "57%" }}
            >
              <span className="text-red-600 font-medium">
                R. C. Chung - Director of Human Resources
              </span>
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="flex items-center justify-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            type="button"
            onClick={() => setShow(false)}
            className="text-white bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Save Certificate
          </button>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
